import { Component, Injector } from '@angular/core';
import { BaseClass } from '../base.class';
import { MatDialog } from '@angular/material/dialog';
import { ProfilePasswordComponent } from './profile-password.component';
import { User } from '../_models/system';
import { HomeService } from 'src/app/_services/home.service';
import { first } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent extends BaseClass {
  data: User = <User>{};

  constructor(_injector: Injector) {
    super(_injector);

    this.injector.get(HomeService).profile()
      .pipe(first())
      .subscribe(
        data => {
          this.data = data;
        }
    );
  }

  changePasswordDialog() {
    const dialogRef = this.injector.get(MatDialog).open(ProfilePasswordComponent, {
      disableClose: true
    })
  }
}
