import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-e-services-bottomsheet',
  templateUrl: './e-services-bottomsheet.component.html',
  styleUrls: ['./e-services-bottomsheet.component.css']
})
export class EServicesBottomSheetComponent {
  //downloadableForms: BusinessDownloadableForms[] = [];
  //@ViewChildren("formLink") links: QueryList<MatListItem>;

  constructor(private _bottomSheetRef: MatBottomSheetRef<EServicesBottomSheetComponent>) {

    //injector.get(HomeService).getBusinessDownloadableForms().toPromise()
    //  .then(result => {
    //    this.downloadableForms = injector.get(HelperService).distinctByProperties(result, ['name', 'link']);
    //  })
    //  .catch(error => {
    //    this.injector.get(Router).navigate(['/']);
    //  });
  }

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  //downloadAllForms() {
  //  this.links.toArray().forEach((link: any) => { 
  //    if (link != null) link._element.nativeElement.click();
  //  });
  //}
}
