<!--<div mat-dialog-title class="mat-dialog-title-icon">
  <mat-icon inline="true">lock</mat-icon><br />
  <span>Data Privacy Notice</span>
</div>-->
<div mat-dialog-content>
  <div class="mat-dialog-header">
    <div class="mat-dialog-header-icon">
      <mat-icon inline="true">lock</mat-icon><br />
      <h2 class="mat-headline" style="margin-bottom:7px;">Data Privacy Notice</h2>
    </div>
  </div>
  <p class="mat-body">SEC. 11. General Data Privacy Principles. – The processing of personal information shall be allowed, subject to compliance with the requirements of this Act and other laws allowing disclosure of information to the public and adherence to the principles of transparency, legitimate purpose and proportionality.  </p>
  <p class="mat-body">Personal information must, be:,</p>
  <p class="mat-body">(a) Collected for specified and legitimate purposes determined and declared before, or as soon as reasonably practicable after collection, and later processed in a way compatible with such declared, specified and legitimate purposes only;</p>
  <p class="mat-body">(b) Processed fairly and lawfully;</p>
  <p class="mat-body">(c) Accurate, relevant and, where necessary for purposes for which it is to be used the processing of personal information, kept up to date; inaccurate or incomplete data must be rectified, supplemented, destroyed or their further processing restricted;</p>
  <p class="mat-body">(d) Adequate and not excessive in relation to the purposes for which they are collected and processed;</p>
  <p class="mat-body">(e) Retained only for as long as necessary for the fulfillment of the purposes for which the data was obtained or for the establishment, exercise or defense of legal claims, or for legitimate business purposes, or as provided by law; and</p>
  <p class="mat-body">(f) Kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the data were collected and processed: Provided, That personal information collected for other purposes may lie processed for historical, statistical or scientific purposes, and in cases laid down in law may be stored for longer periods: Provided, further,That adequate safeguards are guaranteed by said laws authorizing their processing.</p>
  <p class="mat-body">The personal information controller must ensure implementation of personal information processing principles set out herein.</p>
</div>
<div mat-dialog-actions>
  <button mat-flat-button cdkFocusInitial [mat-dialog-close]>OK</button>
</div>
