import { Component, Injector, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BaseClass } from '../base.class';

@Component({
    selector: 'app-view-image',
    templateUrl: './view-image.component.html',
    styleUrls: ['./view-image.component.scss']
})
/** view.image component*/
export class ViewImageComponent extends BaseClass implements AfterViewInit {

  /** view.image ctor */
  constructor(_injector: Injector, private dialogRef: MatDialogRef<ViewImageComponent>, @Inject(MAT_DIALOG_DATA) public data: { imageSource: any }) {
    super(_injector);
  }

  ngAfterViewInit() {
    let imgSource = <HTMLImageElement>document.getElementById("img-viewer");
    imgSource.src = this.data.imageSource;
    setTimeout(() => {
      (<HTMLElement>document.getElementById('img-viewer-loading-screen')).style.display = 'none';
      (<HTMLElement>document.getElementById('img-viewer-body')).style.display = '';
      (<HTMLElement>document.getElementsByClassName('btn-clear-pinch-zoom')[0]).style.display = '';
    }, 300)
    
  }
}
