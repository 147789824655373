import { Component, Injector } from '@angular/core';
import { BaseClass } from '../base.class';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HomeService } from 'src/app/_services/home.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-profile-password',
  templateUrl: './profile-password.component.html',
  styleUrls: ['./profile-password.component.css']
})
export class ProfilePasswordComponent extends BaseClass {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;

  hideoldpassword: boolean = true;
  hidenewpassword: boolean = true;
  hideconfirmnewpassword: boolean = true;

  isLoading: boolean = false;

  constructor(_injector: Injector, private dialogRef: MatDialogRef<ProfilePasswordComponent>) {
    super(_injector);
  }

  changePassword() {
    if (this.oldPassword != null && this.oldPassword.trim().length == 0) {
      this.oldPassword = null;
    }

    if (this.newPassword != null && this.newPassword.trim().length == 0) {
      this.newPassword = null;
    }

    if (this.confirmNewPassword != null && this.confirmNewPassword.trim().length == 0) {
      this.confirmNewPassword = null;
    }

    if (this.oldPassword == null) {
      this.injector.get(MatSnackBar).open("Please enter your old password", 'OK', {
        duration: 2000,
      });
      return false;
    }

    if (this.newPassword == null) {
      this.injector.get(MatSnackBar).open("Please enter your new password", 'OK', {
        duration: 2000,
      });
      return false;
    }

    if (this.confirmNewPassword == null) {
      this.injector.get(MatSnackBar).open("Please enter your confirm new password", 'OK', {
        duration: 2000,
      });
      return false;
    }

    if (this.oldPassword != this.newPassword) {
      if (this.newPassword == this.confirmNewPassword) {
        if (this.newPassword.length > 4) {
          this.isLoading = true;

          this.injector.get(HomeService).changePassword(this.oldPassword, this.newPassword)
            .pipe(first())
            .subscribe(
              data => {
                if (data) {
                  this.isLoading = false;

                  if (data.passwordchanged) {
                    this.injector.get(MatSnackBar).open("The password has been changed successfully.", 'OK', {
                      duration: 3000,
                    });
                    this.dialogRef.close();
                  }
                  else {
                    this.injector.get(MatSnackBar).open(data.validationmsg || "Something went wrong.", 'OK', {
                      duration: 2000,
                    });
                  }
                }
                else {
                  this.isLoading = false;

                  this.injector.get(MatSnackBar).open("Something went wrong.", 'OK', {
                    duration: 2000,
                  });
                }
              },
              error => {
                this.isLoading = false;
              }
            );
        }
        else {
          this.injector.get(MatSnackBar).open("The new password must be at least 5 characters.", 'OK', {
            duration: 2000,
          });
        }
      }
      else {
        this.injector.get(MatSnackBar).open("The new password and confirmation password do not match.", 'OK', {
          duration: 2000,
        });
      }
    }
    else {
      this.injector.get(MatSnackBar).open("The new password must be differ from old password.", 'OK', {
        duration: 2000,
      });
    }
  }
}
