import { Component } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Event as RouterEvent } from "@angular/router";
import { Router } from "@angular/router";
import { RouteConfigLoadEnd } from "@angular/router";
import { RouteConfigLoadStart } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'app';
  asyncLoadCount: number = 0;
  constructor(matSnackBar: MatSnackBar, router: Router) {
    // Notify user if the internet is online or offline.
    window.addEventListener('offline', () => {
      matSnackBar.open('You are currently offline', 'OK', {
        duration: 3000,
      });
    });
    window.addEventListener('online', () => {
      matSnackBar.open('Your internet was restored', 'OK', {
        duration: 3000,
      });
    });

    var isDarkMode = window.localStorage.getItem('isDarkMode') == 'true';

    if (!isDarkMode) {
      document.body.classList.add("light-theme", "mat-app-background");
    }
    else {
      document.body.classList.add("dark-theme", "mat-app-background");
    }

    router.events.subscribe(
      (event: RouterEvent): void => {

        if (event instanceof RouteConfigLoadStart) {
          this.asyncLoadCount++;
        } else if (event instanceof RouteConfigLoadEnd) {
          this.asyncLoadCount--;
        }
        else {
          this.asyncLoadCount = 0;
        }
      }
    );
  }
}
