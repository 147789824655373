import { Component, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseClass } from '../base.class';
import { getTransactionLocalStorage, TransactionLocalStorage } from '../_models/system';

@Component({
  selector: 'app-home-payment-summary',
  templateUrl: './home-payment-summary.component.html',
  styleUrls: ['./home-payment-summary.component.scss']
})
/** home-payment-summary component*/
export class HomePaymentSummaryComponent extends BaseClass {
  public busid: string;
  public receiptnumber: string;
  public payorname: string;
  public refnumber: string;
  public gatewayrefnumber: string;
  public gateway: string
  public amount: number;
  public tdnos: string;
  public transactiondata: TransactionLocalStorage;

  /** home-payment-summary ctor */
  constructor(_injector: Injector) {
    super(_injector);
    this.transactiondata = getTransactionLocalStorage(this.injector.get(ActivatedRoute).snapshot.queryParams['refnumber']);
    this.busid = this.transactiondata?.busID;
    this.receiptnumber = this.transactiondata?.miscTransaction?.uovrNo;
    this.payorname = this.transactiondata?.payorName;
    this.refnumber = this.injector.get(ActivatedRoute).snapshot.queryParams['refnumber'];
    this.gatewayrefnumber = this.injector.get(ActivatedRoute).snapshot.queryParams['gatewayrefnumber'];
    this.gateway = this.transactiondata?.gateway;
    this.amount = this.transactiondata?.amount;
    this.tdnos = this.transactiondata?.rptTDNos?.join(', ');
    this.injector.get(Router).navigate([]);
  }

  backToHomePage() {
    console.log(this.transactiondata)
    if (this.transactiondata?.busID != null) {
      this.injector.get(Router).navigate(['/Home'], { queryParams: { backToHomePage: true, paymentSummaryBusinessID: this.transactiondata?.busID } });
    }
    if (this.transactiondata?.rptTDNos != null) {
      this.injector.get(Router).navigate(['/RealProperty/Payment'], { queryParams: { backToHomePage: true, paymentSummaryRealProperty: this.transactiondata?.rptTDNos } });
    }
    if (this.transactiondata?.miscTransaction != null) {
      this.injector.get(Router).navigate(['/Ovr'],
        {
          queryParams: {
            receiptNumber: this.transactiondata?.miscTransaction?.uovrNo,
            licensenumber: this.transactiondata?.miscTransaction?.licensenumber,
            lastname: this.transactiondata?.miscTransaction?.lastname
          }
        });
    }
    else {
      this.injector.get(Router).navigate(['/']);
    }
  }
}
