<mat-drawer-container class="body-container" autosize>
  <mat-drawer #drawer class="sidenav" mode="over">
    <h5 class="sidenav-title">Menu</h5>
    <hr />
    <app-nav-menu [toolbarTitle]="routeTitle" (notifyLayoutEvent)="notify($event)"></app-nav-menu>
  </mat-drawer>
  <div class="sidenav-content">
    <div class="container-fluid pt-3">
      <div style="width:300px;margin-bottom:1.5rem;">
        <button type="button" mat-button (click)="drawer.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
        <a mat-button [routerLink]="'/BusinessTax'" class="mat-button-main-icon">
          <img class="nav-icon" src="/images/navotas_seal.ico" />&nbsp;
          <span>Navotas</span>
        </a>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</mat-drawer-container>
