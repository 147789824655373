import { AfterViewInit, Component, Injector } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserBusiness } from '../_models/system';
import { BaseClass } from '../base.class';
import { PaymentService } from '../_services/businesspermit.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ViewImageComponent } from '../shared/view-image.component';
import { PromptMessageComponent } from '../shared/prompt-message.component';


@Component({
  selector: 'app-home-business-add',
  templateUrl: './home-business-add.component.html',
  styleUrls: ['./home-business-add.component.scss']
})
export class HomeBusinessAddComponent extends BaseClass implements AfterViewInit {
  userBusiness: UserBusiness = <UserBusiness>{};
  formgroup_business_add: FormGroup;

  isLoading: boolean = false;

  public maskBusinessID = [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]

  /** home.business.add ctor */
  constructor(_injector: Injector, private dialogRef: MatDialogRef<HomeBusinessAddComponent>, private formBuilder: FormBuilder) {
    super(_injector);

    this.formgroup_business_add = this.formBuilder.group({
      'validate_busid': ['', Validators.required],
      'validate_orno': ['', Validators.required],
      'validate_amount': ['', Validators.required]
    });
  }

  ngAfterViewInit() {
    this.formgroup_business_add.get('validate_busid').markAsTouched();
    this.formgroup_business_add.get('validate_orno').markAsTouched();
    this.formgroup_business_add.get('validate_amount').markAsTouched();
  }

  viewImage(imgsource: string) {
    let dialogRef = this.injector.get(MatDialog).open(ViewImageComponent, {
      data: { imageSource: imgsource }
    });
  }

  add() {
    if (this.userBusiness) {
      if (this.userBusiness.busID && this.userBusiness.busID.length > 0) {
        if (this.userBusiness.orNumber > 0) {
          if (this.userBusiness.amount > 0) {
            this.isLoading = true;

            this.injector.get(PaymentService).addBusiness(this.userBusiness)
              .pipe(first())
              .subscribe(
                data => {
                  this.isLoading = false;

                  if (data.error) {
                    this.injector.get(MatSnackBar).open(data.error, 'OK', {
                      duration: 10000,
                    });
                  }
                  else {
                    this.dialogRef.close(data.model);
                    this.injector.get(MatSnackBar).open('Business has been added successfully.', 'OK', {
                      duration: 3000,
                    });
                  }
                },
                error => {
                  this.isLoading = false;
                }
              );
          }
          else {
            this.injector.get(MatSnackBar).open('Amount must be greater than zero.', 'OK', {
              duration: 2000,
            });
          }
        }
        else {
          this.injector.get(MatSnackBar).open('Please enter a valid OR Number.', 'OK', {
            duration: 2000,
          });
        }
      }
      else {
        this.injector.get(MatSnackBar).open('Please enter a valid Business ID.', 'OK', {
          duration: 2000,
        });
      }
    }
  }
}
