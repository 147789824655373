import { Component, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseClass } from '../base.class';
import { getTransactionLocalStorage, TransactionLocalStorage } from '../_models/system';

@Component({
    selector: 'app-home-payment-cancelled',
    templateUrl: './home-payment-cancelled.component.html',
    styleUrls: ['./home-payment-cancelled.component.scss']
})
/** home-payment-cancelled component*/
export class HomePaymentCancelledComponent extends BaseClass {

  public busid: string;
  public receiptnumber: string;
  public gateway: string;
  public tdnos: string;
  public transactiondata: TransactionLocalStorage;

  /** home-payment-cancelled ctor */
  constructor(_injector: Injector) {
    super(_injector);
    this.transactiondata = getTransactionLocalStorage(this.injector.get(ActivatedRoute).snapshot.queryParams['refnumber']);
    this.busid = this.transactiondata?.busID;
    this.receiptnumber = this.transactiondata?.miscTransaction?.uovrNo;
    this.gateway = this.transactiondata?.gateway;
    this.tdnos = this.transactiondata?.rptTDNos?.join(', ');
    this.injector.get(Router).navigate([]);
    //(<HTMLElement>document.getElementsByTagName('body')[0]).classList.add('payment-cancelled');
  }
}
