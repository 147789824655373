import { Injectable, Injector } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import * as jwt_decode from "jwt-decode";
import { BaseClass } from '../base.class';
import { environment } from '../../environments/environment';
import { IHttpConnectionOptions } from '@aspnet/signalr';

@Injectable({
  providedIn: 'root'
})
export class SignalRService extends BaseClass {

  constructor(_injector: Injector) {
    super(_injector);
  }

  public hubConnection: signalR.HubConnection
  private signalRToken: string = jwt_decode(localStorage.getItem('currentUser')).SignalRToken;

  startConnection = () => {
    const options: signalR.IHttpConnectionOptions = {
      transport: signalR.HttpTransportType.WebSockets,
      accessTokenFactory: () => {
        return this.signalRToken;
      }
    };

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.apiurl}/Api/OnlinePaymentHub`, options)
      .withAutomaticReconnect()
      .build();

    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .then(() => this.hubConnection.invoke("joinGroup"))
      .catch(err => console.log(err))
  }

  stopConnection() {
    this.hubConnection
      .stop()
      .then(() => console.log('Connection stopped'))
      .catch(err => console.log(err));
  }
}
