import { Component, Injector } from '@angular/core';
import { BaseClass } from '../base.class';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from 'src/app/_services/home.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';
import { HelperService } from '../_services/helper.service';
import { MatDialog } from '@angular/material/dialog';
import { PromptMessageComponent } from '../shared/prompt-message.component';

@Component({
  selector: 'app-login-reset-password',
  templateUrl: './login-reset-password.component.html',
  styleUrls: ['./login-reset-password.component.scss']
})
/** login.reset.password component*/
export class LoginResetPasswordComponent extends BaseClass {
  public code: string;
  public emailAddress: string;
  public hidepassword: boolean = true;
  public hideconfirmpassword: boolean = true;
  public password: string;
  public confirmpassword: string;

  isLoading: boolean = false;

  /** login.reset.password ctor */
  constructor(_injector: Injector) {
    super(_injector)

    this.code = this.injector.get(ActivatedRoute).snapshot.queryParams['code'];
    this.emailAddress = this.injector.get(ActivatedRoute).snapshot.queryParams['email'];
    var error = this.injector.get(ActivatedRoute).snapshot.queryParams['error'];
    if (error == 'true') {
      this.injector.get(MatSnackBar).open("Your password reset request has been expired or has invalid token.", 'OK', {
        duration: 3000,
      });
      this.injector.get(Router).navigate(['/Login']);
    }
  }

  confirm() {
    if (this.emailAddress == undefined || this.emailAddress?.trim().length === 0 || this.confirmpassword == undefined || this.confirmpassword?.trim().length === 0) {
      this.injector.get(MatSnackBar).open("Please fill-out the required fields", 'OK', {
        duration: 3000,
      });
      return;
    } 

    if (this.password == this.confirmpassword) {
      if (this.password.length > 4) {
        this.injector.get(HelperService).disableClickableHTMLElements(document, true);
        this.isLoading = true;

        setTimeout(() => {
          this.injector.get(HomeService).resetPassword(this.code, this.password)
            .pipe(first())
            .subscribe(
              data => {
                if (data.verified) {
                  this.injector.get(MatSnackBar).open("Password has been succesfully changed", 'OK');
                  this.injector.get(MatDialog).open(PromptMessageComponent, {
                    data: { type: "html", icon: "check_circle", title: "Password has been changed successfully", items: '<p class="mat-subheading-2">You can log in using your recently updated password.</p>' },
                    disableClose: true,
                    width: '450px',
                    panelClass: ['mat-header', 'mat-header-success']
                  });
                  this.injector.get(HomeService).logout();
                  this.injector.get(Router).navigate(['/Login']);
                }
                else {
                  this.injector.get(HelperService).disableClickableHTMLElements(document, false);
                  this.isLoading = false;

                  this.injector.get(MatSnackBar).open(data.validationmsg || 'Something went wrong.', 'OK', {
                    duration: 2000,
                  });
                }
              },
              error => {
                this.injector.get(HelperService).disableClickableHTMLElements(document, false);
                this.isLoading = false;
              }
            );
        }, 1000);
      }
      else {
        this.injector.get(MatSnackBar).open('The password must be at least 5 characters.', 'OK', {
          duration: 3000,
        });
      }
    }
    else {
      this.injector.get(MatSnackBar).open("The password and confirm password do not match.", 'OK', {
        duration: 3000,
      });
    }

  }

  backToSignIn() {
    this.injector.get(Router).navigate(['/Login']);
  }
}
