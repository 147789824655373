<div class="login">
  <div class="container">
    <div class="login-form mat-elevation-z2">
      <div class="row">
        <div class="login-column-header col-md-6">
          <div class="row">
            <div class="col-md-12 col-sm-4 col-xs-4 col-4">
              <div class="login-logo">
                <img src="/images/navotenoaako_logo.png" class="img-fluid" />
              </div>
            </div>
            <div class="col-md-12 col-sm-8 col-xs-8 col-8">
              <div class="login-logo-description">
                <img src="/images/online_services_and_description.png" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div class="login-column-content col-md-6">
          <h2 class="mat-headline mt-3 mb-4 font-weight-bold">Reset Mobile Number</h2>
          <small>We just sent an SMS with a One-Time Password (OTP) code to your mobile number <span class="font-weight-bold">{{ mobilenumber }}</span>.</small><br/>
          <small>Enter the OTP Code that you've received below for your registered email address: <b>{{ emailAddress }}</b></small>
          <div class="mt-3 mb-2" [hidden]="resendtimeoutseconds == 0">
            <small>Please wait {{ resendtimeoutseconds }} seconds to resend the code.</small>
          </div>
          <div class="mt-3 mb-2" [hidden]="resendtimeoutseconds != 0">
            <small>Didn't get the OTP Code? <a href="javascript:void(0)" (click)="resend()">Click here to resend</a>.</small>
          </div>
          <!--OTP Code textbox-->
          <mat-form-field appearance="fill" class="mt-3 mb-4">
            <mat-label>Your OTP Code</mat-label>
            <input matInput [(ngModel)]="otpcode" autocomplete="off" (keypress)="txtOTPCodeEvent($event)" #txtOTPCode required>
            <mat-error><b>OTP Code is required</b></mat-error>
          </mat-form-field>
          <div class="row mt-2">
            <div class="col-8">
              <button mat-button class="login-btn-back" (click)="backToSignIn()">Back to Log In</button>
            </div>
            <div class="col-4">
              <div class="text-right">
                <button mat-flat-button color="primary" (click)="confirm()">Reset</button>
              </div>
            </div>
          </div>
          <div class="loading-screen" *ngIf="isLoading">
            <div class="div-center">
              <mat-spinner></mat-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

