<h1 mat-dialog-title>Change password</h1>
<div mat-dialog-content>
  <div class="row mt-1">
    <div class="col-md-12">
      <mat-form-field appearance="outline">
        <mat-label>Old password</mat-label>
        <input matInput [(ngModel)]="oldPassword" [type]="hideoldpassword ? 'password' : 'text'">
        <button type="button" mat-icon-button matSuffix (click)="hideoldpassword = !hideoldpassword" [attr.aria-label]="'Hide old password'" tabindex="-1">
          <mat-icon>{{hideoldpassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <mat-form-field appearance="outline">
        <mat-label>New password</mat-label>
        <input matInput [(ngModel)]="newPassword" [type]="hidenewpassword ? 'password' : 'text'">
        <button type="button" mat-icon-button matSuffix (click)="hidenewpassword = !hidenewpassword" [attr.aria-label]="'Hide new password'" tabindex="-1">
          <mat-icon>{{hidenewpassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <mat-form-field appearance="outline">
        <mat-label>Confirm new password</mat-label>
        <input matInput [(ngModel)]="confirmNewPassword" [type]="hideconfirmnewpassword ? 'password' : 'text'">
        <button type="button" mat-icon-button matSuffix (click)="hideconfirmnewpassword = !hideconfirmnewpassword" [attr.aria-label]="'Hide confirm new password'" tabindex="-1">
          <mat-icon>{{hideconfirmnewpassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button class="mr-3" [mat-dialog-close]>Cancel</button>
  <button mat-flat-button color="primary" (click)="changePassword()">Confirm</button>
</div>
<div class="loading-screen" *ngIf="isLoading">
  <div class="div-center">
    <mat-spinner></mat-spinner>
  </div>
</div>
