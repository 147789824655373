<div class="row mb-2">
  <div class="col-md-12">
    <label class="text-center mb-3">The One-Time Password (OTP) has been sent to this mobile no. <span class="font-weight-bold">{{ data.registration.mobileNumber }}</span>.</label>
    <mat-form-field>
      <input matInput placeholder="Enter the One-Time Password (OTP) here" [(ngModel)]="otpCode" autocomplete="off">
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]>Cancel</button>
  <button mat-button color="primary" (click)="verify()" cdkFocusInitial>Confirm</button>
</div>
