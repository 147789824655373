import { Component, Injector, Inject } from '@angular/core';
import { BaseClass } from '../base.class';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Registration } from '../_models/system';
import { HomeService } from 'src/app/_services/home.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login-registration-mobilenumber',
  templateUrl: './login-registration-mobilenumber.component.html',
  styleUrls: ['./login-registration-mobilenumber.component.css']
})
export class LoginRegistrationMobilenumberComponent extends BaseClass {
  otpCode: string;

  constructor(_injector: Injector, private dialogRef: MatDialogRef<LoginRegistrationMobilenumberComponent>, @Inject(MAT_DIALOG_DATA) public data: { registration: Registration }) {
    super(_injector);
  }

  verify() {
    return this.injector.get(HomeService).registerVerifyOTP(this.data.registration, this.otpCode)
      .pipe(first())
      .subscribe(
        data => {
          if (data) {
            if (data.success) {
              this.injector.get(MatSnackBar).open('Mobile verification success.', 'OK', {
                duration: 2000,
              });
              this.dialogRef.close(this.otpCode);
            }
            else {
              this.injector.get(MatSnackBar).open(data.validationmsg || 'Something went wrong.', 'OK', {
                duration: 2000,
              });
            }
          }
          else {
            this.injector.get(MatSnackBar).open('Something went wrong.', 'OK', {
              duration: 2000,
            });
          }
        }
    );
  }
}
