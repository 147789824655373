import { BaseClass } from "../../base.class";
import { Injectable, Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Vw_BusinessInformation } from "../../_models/business/applicationlist";

@Injectable()
export class ApplicationListService extends BaseClass {
  constructor(_injector: Injector) {
    super(_injector);
  }

  getList() {
    return this.injector.get(HttpClient).get<any>(`OnlineApplication/Business/Applications/Get`);
  }

  delete(id: number) {
    return this.injector.get(HttpClient).delete<any>(`OnlineApplication/Business/Applications/Delete?id=${id}`, {});
  }

  submit(id: number) {
    return this.injector.get(HttpClient).post<any>(`OnlineApplication/Business/Applications/Submit?id=${id}`, {});
  }

  cancel(ID: number, Remarks: string) {
    return this.injector.get(HttpClient).post<{ error: string, model: Vw_BusinessInformation }>(`OnlineApplication/Business/Applications/Cancel`, { ID, Remarks });
  }
}
