import { Component, Injector, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { BaseClass } from '../base.class';
import { Router, ActivatedRoute } from '@angular/router';
import { HomeService } from 'src/app/_services/home.service';
import { first } from 'rxjs/operators';
import { MatButton } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HelperService } from '../_services/helper.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent extends BaseClass implements AfterViewInit {
  public username: string;
  public password: string;
  public otpcode: string
  public hide: boolean = true;
  public otpvalidation: boolean = false;
  public otpmobilenumber: string;
  public resendtimeoutseconds: number = 0;

  isLoading: boolean = false;

  returnUrl: string;

  @ViewChild('txtUsername', { static: false }) txtUsername: ElementRef<HTMLElement>;
  @ViewChild('txtPassword', { static: false }) txtPassword: ElementRef<HTMLElement>;
  @ViewChild('btnLogin', { static: false }) btnLogin: MatButton;
  @ViewChild('txtOTPCode', { static: false }) txtOTPCode: ElementRef<HTMLElement>;
  @ViewChild('btnOTPVerify', { static: false }) btnOTPVerify: MatButton;

  constructor(_injector: Injector) {
    super(_injector);

    this.returnUrl = this.injector.get(ActivatedRoute).snapshot.queryParams['returnUrl'];
  }

  ngAfterViewInit(): void {
    this.txtUsername.nativeElement.focus();
    this.injector.get(ChangeDetectorRef).detectChanges();
  }

  txtUsernameEvent(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      this.txtPassword.nativeElement.focus();
    }
  }

  txtPasswordEvent(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      if (this.username && this.username.length > 0) {
        (<HTMLElement>this.btnLogin._elementRef.nativeElement).click();
      }
      else {
        this.txtUsername.nativeElement.focus();
      }
    }
  }

  txtOTPCodeEvent(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      (<HTMLElement>this.btnOTPVerify._elementRef.nativeElement).click();
    }
  }

  login() {
    if (this.otpvalidation && (this.otpcode == undefined || this.otpcode == '')) {
      this.injector.get(MatSnackBar).open("Please enter the OTP Code", 'OK', {
        duration: 2000,
      });

      return false;
    }

    this.injector.get(HelperService).disableClickableHTMLElements(document, true);
    this.isLoading = true;

    setTimeout(() => {
      this.injector.get(HomeService).login((this.username || ''), (this.password || ''), this.otpcode)
        .pipe(first())
        .subscribe(
          data => {
            if (data) {
              if (data.loginsuccess) {
                if (this.returnUrl != null) {
                  this.injector.get(Router).navigateByUrl(this.returnUrl);
                }
                else {
                  this.injector.get(Router).navigate(['/'], { queryParams: { showNotice: true } });
                }
              }
              else if (data.otpcreated) {
                this.otpvalidation = true;
                this.otpmobilenumber = data.mobilenumber;
                this.injector.get(MatSnackBar).open(`A One-Time Password was sent to your mobile number.`, 'OK', {
                  duration: 2500,
                });

                this.resendtimeoutseconds = data.resendtimeoutseconds;
                var _this = this;
                var timerid = window.setInterval(function () {
                  _this.resendtimeoutseconds--;
                  if (_this.resendtimeoutseconds == 0) {
                    clearInterval(timerid)
                  }
                }, 1000);

                this.injector.get(ChangeDetectorRef).detectChanges();
                this.txtOTPCode.nativeElement.focus();
                this.injector.get(HelperService).disableClickableHTMLElements(document, false);
                this.isLoading = false;
              }
              else {
                this.injector.get(HelperService).disableClickableHTMLElements(document, false);
                this.isLoading = false;

                this.injector.get(MatSnackBar).open(data.validationmsg || "Something went wrong.", 'OK', {
                  duration: 2000,
                });
              }
            }
            else {
              this.injector.get(HelperService).disableClickableHTMLElements(document, false);
              this.isLoading = false;

              this.injector.get(MatSnackBar).open("Something went wrong.", 'OK', {
                duration: 2000,
              });
            }
          },
          error => {
            this.injector.get(HelperService).disableClickableHTMLElements(document, false);
            this.isLoading = false;
          }
        );
    }, 1000);
  }

  backtologin() {
    this.otpvalidation = false;
    this.otpcode = undefined;
    this.username = undefined;
    this.password = undefined;
    this.injector.get(ChangeDetectorRef).detectChanges();
    this.txtUsername.nativeElement.focus();
  }

  resend() {
    this.injector.get(HelperService).disableClickableHTMLElements(document, true);
    this.isLoading = true;

    this.injector.get(HomeService).login((this.username || ''), (this.password || ''), undefined)
      .pipe(first())
      .subscribe(
        data => {
          this.injector.get(HelperService).disableClickableHTMLElements(document, false);
          this.isLoading = false;

          if (data) {
            if (data.otpcreated) {
              this.injector.get(MatSnackBar).open("Resend Code Success.", 'OK', {
                duration: 2000,
              });

              this.resendtimeoutseconds = data.resendtimeoutseconds;
              var _this = this;
              var timerid = window.setInterval(function () {
                _this.resendtimeoutseconds--;
                if (_this.resendtimeoutseconds == 0) {
                  clearInterval(timerid)
                }
              }, 1000);
            }
            else {
              this.injector.get(MatSnackBar).open(data.validationmsg || "Something went wrong.", 'OK', {
                duration: 2000,
              });
            }
          }
          else {
            this.injector.get(MatSnackBar).open("Something went wrong.", 'OK', {
              duration: 2000,
            });
          }
        },
        error => {
          this.injector.get(HelperService).disableClickableHTMLElements(document, false);
          this.isLoading = false;
        }
      );
  }

  register() {
    this.injector.get(MatSnackBar).open('You can now create an account.', 'OK', {
      duration: 2000,
    });
    this.injector.get(Router).navigate(['/Register']);
  }

  forgotPassword() {
    this.injector.get(MatSnackBar).open('Please enter the email address to send confirmation.', 'OK', {
      duration: 2000,
    });
    this.injector.get(Router).navigate(['/ForgotPassword']);
  }

  forgotMobileNumber() {
    this.injector.get(MatSnackBar).open('Please enter the email address to send confirmation.', 'OK', {
      duration: 2000,
    });
    this.injector.get(Router).navigate(['/ForgotMobileNumber']);
  }
}
