import { Component, Injector } from '@angular/core';
import { BaseClass } from '../base.class';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login-registration-confirm',
  templateUrl: './login-registration-confirm.component.html',
  styleUrls: ['./login-registration-confirm.component.scss']
})
/** login.registration.confirm component*/
export class LoginRegistrationConfirmComponent extends BaseClass {

  success: string;
  alreadyExist: string;

  /** login.registration.confirm ctor */
  constructor(_injector: Injector) {
    super(_injector)

    this.success = this.injector.get(ActivatedRoute).snapshot.queryParams['success'];
    if (this.success == 'true') {
      this.injector.get(MatSnackBar).open("Your registration has been successfully verified. You can now login your account below.", 'OK', {
        duration: 0,
        verticalPosition: 'top',
        panelClass: ['register-snackbar', 'snackbar-success']
      });
      this.injector.get(Router).navigate(['/Login']);
    }
    else if (this.success == 'false') {
      this.injector.get(MatSnackBar).open("Your registration has been expired or has invalid token.", 'OK', {
        duration: 0,
        verticalPosition: 'top',
        panelClass: ['register-snackbar', 'snackbar-error']
      });
      this.injector.get(Router).navigate(['/Login']);
    }

    this.alreadyExist = this.injector.get(ActivatedRoute).snapshot.queryParams['alreadyExist'];
    if (this.alreadyExist == 'true') {
      this.injector.get(MatSnackBar).open("User already exist.", 'OK', {
        duration: 0,
        verticalPosition: 'top',
        panelClass: ['register-snackbar', 'snackbar-error']
      });
      this.injector.get(Router).navigate(['/Login']);
    }
  }
}
