<div class="payment-status payment-success">
  <div class="container payment-summary-status">
    <div class="div-center">
      <div class="payment-summary-status-body">
        <mat-icon class="payment-icon success">check_circle</mat-icon>
        <h1 class="payment-title mat-display-1 text-center" style="margin-bottom:28px;">Payment Successful</h1>
        <h2 class="mat-title">Payment Details</h2>
        <dl *ngIf="busid != null" class="row mb-0">
          <dt class="col-sm-12 col-md-4">
            <label class="mb-0">Business ID</label>
          </dt>
          <dd class="col-sm-12 col-md-8">
            <label class="mb-0">{{ busid | uppercase }}</label>
          </dd>
        </dl>
        <dl *ngIf="tdnos != null" class="row mb-0">
          <dt class="col-sm-12 col-md-4">
            <label class="mb-0">Tax Declaration No.</label>
          </dt>
          <dd class="col-sm-12 col-md-8">
            <label class="mb-0">{{ tdnos | uppercase }}</label>
          </dd>
        </dl>
        <dl *ngIf="payorname != null" class="row mb-0">
          <dt class="col-sm-12 col-md-4">
            <label class="mb-0">Payor Name</label>
          </dt>
          <dd class="col-sm-12 col-md-8">
            <label class="mb-0">{{ payorname | uppercase }}</label>
          </dd>
        </dl>
        <dl *ngIf="refnumber != null" class="row mb-0">
          <dt class="col-sm-12 col-md-4">
            <label class="mb-0">Reference No.</label>
          </dt>
          <dd class="col-sm-12 col-md-8">
            <label class="mb-0">{{ refnumber }}</label>
          </dd>
        </dl>
        <dl *ngIf="gatewayrefnumber != null" class="row mb-0">
          <dt class="col-sm-12 col-md-4">
            <label class="mb-0">Gateway Ref. No.</label>
          </dt>
          <dd class="col-sm-12 col-md-8">
            <label class="mb-0">{{ gatewayrefnumber }}</label>
          </dd>
        </dl>
        <dl *ngIf="amount != null" class="row mb-0">
          <dt class="col-sm-12 col-md-4">
            <label class="mb-0">Payment Amount</label>
          </dt>
          <dd class="col-sm-12 col-md-8">
            <label class="mb-0">{{ amount | currency:'&#8369; ' }}</label>
          </dd>
        </dl>
        <a mat-raised-button color="primary" class="btn-home" (click)="backToHomePage()">Back to Payment Page</a>
      </div>
    </div>
  </div>
</div>
