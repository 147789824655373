import { Component, EventEmitter, Injector, Input, OnInit } from '@angular/core';
import { BaseClass } from '../base.class';
import { HomeService } from 'src/app/_services/home.service';
import { Navigation, Page } from 'src/app/_models/system';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { OverlayContainer } from '@angular/cdk/overlay';
import { environment } from '../../environments/environment';
import { Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent extends BaseClass implements OnInit {
  isDarkMode: boolean = window.localStorage.getItem('isDarkMode') == 'true';
  @Input() toolbarTitle: string;
  isExpanded = false;
  navigations: Navigation[];
  pages: Page[];
  @Output() public notifyLayoutEvent = new EventEmitter();


  constructor(_injector: Injector, private overlay: OverlayContainer) {
    super(_injector);

    this.injector.get(HomeService).getNavigation()
      .pipe(first())
      .subscribe(
        data => {
          this.navigations = data.navigations;
          this.pages = data.pages;
        }
    );
  }

  ngOnInit() {
    if (!this.isDarkMode) {
      this.overlay.getContainerElement().classList.add("light-theme");
    }
    else {
      this.overlay.getContainerElement().classList.add("dark-theme");
    }
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
  logout() {
    this.injector.get(HomeService).logout()
    this.injector.get(Router).navigate(['/']);
    this.injector.get(MatSnackBar).open(`You have been logged-out successfully.`, 'OK', {
      duration: 2500,
    });
  }

  openMenu() {
    var x = document.getElementById("top-navigation");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }

  toggleTheme(): void {
    if (this.overlay.getContainerElement().classList.contains("dark-theme")) {
      this.overlay.getContainerElement().classList.remove("dark-theme");
      this.overlay.getContainerElement().classList.add("light-theme");
      window.localStorage.setItem('isDarkMode', 'false');
    } else if (this.overlay.getContainerElement().classList.contains("light-theme")) {
      this.overlay.getContainerElement().classList.remove("light-theme");
      this.overlay.getContainerElement().classList.add("dark-theme");
      window.localStorage.setItem('isDarkMode', 'true');
    } else {
      this.overlay.getContainerElement().classList.add("light-theme");
      window.localStorage.setItem('isDarkMode', 'false');
    }

    if (document.body.classList.contains("dark-theme")) {
      document.body.classList.remove("dark-theme");
      document.body.classList.add("light-theme");
    } else if (document.body.classList.contains("light-theme")) {
      document.body.classList.remove("light-theme");
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.add("light-theme");
    }
  }

  notifyLayout(event) {
    this.notifyLayoutEvent.emit(event)
  }
}
