export enum StatusType {
  SAVED_AS_DRAFT = 0,
  APPLICATION = 1,
  APPROVED = 2,
  CPDO_APPROVED = 8,
  CPDO_DISAPPROVED = 9,
  CPDO_FOR_INSPECTION = 10,
  LBO_APPROVED = 11,
  LBO_DISAPPROVED = 12,
  BPLO_APPLICATION = 14,
  BPLO_APPROVED = 15,
  BPLO_DISAPPROVED = 16,
  CANCELLED = 18
}

export const environment = {
  production: true,
  apiurl: '',
  fileSizeLimit: 10 * 1024 * 1024, // [Desired Megabytes] × 1024 × 1024
  attachmentAllowedFileFormats: [
    "image/jpeg",
    "image/jpg",
    "image/pjpeg",
    "image/apng",
    "image/png",
    "image/x-png"
  ],
  status_type_ids_for_editing_business: [
    StatusType.CPDO_DISAPPROVED,
    StatusType.LBO_DISAPPROVED,
    StatusType.BPLO_DISAPPROVED,
  ],
  status_type_ids_color_classes: [
    // Disapproved/Need to edit colors
    { id: StatusType.CPDO_DISAPPROVED, color: "red" },
    { id: StatusType.LBO_DISAPPROVED, color: "red" },
    { id: StatusType.BPLO_DISAPPROVED, color: "red" },
    // Waiting/Pending
    { id: StatusType.SAVED_AS_DRAFT, color: "orange" },
    { id: StatusType.BPLO_APPLICATION, color: "orange" },
    { id: StatusType.CPDO_APPROVED, color: "orange" },
    { id: StatusType.LBO_APPROVED, color: "orange" },
    { id: StatusType.BPLO_APPROVED, color: "orange" },
    { id: StatusType.CPDO_FOR_INSPECTION, color: "orange" },
    // Approved/Success
    { id: StatusType.APPROVED, color: "green" },
    // Waiting for Payment
    { id: StatusType.APPLICATION, color: "blue" },
    // cancelled
    { id: StatusType.CANCELLED, color: "black" },
  ],
  status_type_remarks: [
    { id: StatusType.SAVED_AS_DRAFT, remarks: "Your business application has been saved in draft mode, allowing you to continue editing your business information at your convenience." },
    { id: StatusType.BPLO_APPLICATION, remarks: "Please be patient as your business application is currently being processed." },
    { id: StatusType.CPDO_APPROVED, remarks: "Your business application has been approved by City Planning and Development Office." },
    { id: StatusType.LBO_APPROVED, remarks: "Your business application has been approved by Local Building Office." },
    { id: StatusType.BPLO_APPROVED, remarks: "Your business application has been approved by Business Permit and Licensing Office." },
    { id: StatusType.APPLICATION, remarks: "Your business application has been approved. You can pay your business tax now." },
    { id: StatusType.APPROVED, remarks: "Your business tax payment has been processed. If you have any remaining business taxes pending, please settle them promptly." }
  ],
  paymentTypes: [
    { id: 0, description: "Annual" },                                
    { id: 1, description: "1st" },
    { id: 2, description: "2nd" },
    { id: 3, description: "3rd" },
    { id: 4, description: "4th" },
    { id: 5, description: "1st Semi-Annual" },
    { id: 6, description: "2nd Semi-Annual" },
    { id: 7, description: "Next Year" }
  ],
  maskTinFormat: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]
};
