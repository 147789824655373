<mat-nav-list>
  <!--Online-->
  <h2 class="mat-headline ml-3 mt-3 mb-0">Online</h2>
  <p class="mat-headline-subtext mat-body ml-3 mr-3 mt-1">Choose here whether you are applying for a new business or renewing via online.</p>
  <div class="row">
    <!--New Business-->
    <div class="col-md-12">
      <a mat-list-item [routerLink]="['/OnlineApplication/Business/Register']" (click)="openLink($event)">
        <span mat-line class="mat-nav-list-option font-weight-bold">New Business</span>
        <span mat-line title="Register your business so you can pay your business taxes to our trusted banks and e-wallets.">Register your business so you can pay your business taxes to our trusted banks and e-wallets.</span>
      </a>
    </div>
    <!--Renew Business-->
    <div class="col-md-12">
      <a mat-list-item [routerLink]="['/Home']" (click)="openLink($event)">
        <span mat-line class="mat-nav-list-option font-weight-bold">Renew Business</span>
        <span mat-line>Re-apply your business.</span>
      </a>
    </div>
  </div>
  <!--<hr />-->
  <!--On-site-->
  <!--<h2 class="mat-headline ml-3 mb-0" style="display:inline-block;">On-site</h2>
  <button mat-button color="primary" (click)="downloadAllForms()" class="ml-2">Download all forms</button>
  <p class="mat-headline-subtext mat-body ml-3 mr-3">Here are the necessary forms for applying/renewing your business. You can download them all & complete the required information before heading to Navotas City Hall.</p>
  <div class="row">-->
    <!--Loop all forms to download-->
    <!--<ng-container *ngFor="let download_form of downloadableForms">
      <div class="col-md-6">
        <a #formLink mat-list-item [href]="download_form.link" [download]="download_form.name + '.pdf'" [title]="download_form.name">
          <span mat-line></span>
          <span mat-line class="mat-nav-list-option">
            <img class="downloadable-form-icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAADq0lEQVR4nO2YW0gUURzG/wRFwa4760N2p4jChygour0IURCRdCONkjTTwih6iKSorKhIuki1FVlJpVtT9tBdUopICiOLisyoVOyyupnp1q6rabv7xYzbzM66GkWLR+d88D3sOf8znN98s3POHCIuLi4url4kRBLC5s0bXmDHjj6kG2DRCmzfVImkpP6kG2DRCuzeZkdKXCTpBli0Apm7HEiMG6ofYNEKZGW6kbQoWj/AohXeIwdbkRo/TTfAkKCzj3mxYtki3QBDtMJz+oQPKQmpugGGlPS5HHjSErfoBhgSdN5ZtKQlHdYNMEQrfOdz0bhmZb5ugCH5Qh5sa1OLezSwaPizLxsI94yEchPhm5nQ64AvGgiFRsILE8EuELxBY3oF8A0joTSC8EEgtP1hTI8GrhYIzea/G9OjgfEP5sD/W2AgVZ5wOIVIvSecHKd1bAwwwqitkX4H1ixfCMyeDgzqFxpk2Txg1dLQ7nbgUGp2A0cPAFF922umRocsg8sJWPYDQwZor2n7iE7FCnBbYwNaamzweb3q5HKOyzXuKSrwj892tDkatRDPSoHhBuWaLZ/agT3uJvyw12rMDPDzrenyLunKqIGoe1jsvwttwCgBrgDg4sWxct3VsUNRmZujQp88olyz2Q/89oSlw1aTGeDqjHS8MxGeRhBKEuNVkJgJGuCK+Fi8MREeRxDyDYSawltyu8/dBAzurwH+lG1BhYk0Zga4LiNdaavP2tve6PPBNTZKA/x1SaxS5zQTSlMS1JszY5IG2FFwHYifo3rmZHaAXUUFwJ6twNV8wOOR2+x3i+RPuM6AJb+fP0vpa5kbowHuoPt32AEO1rfyMhSMGSJ/znUF7MzYqPaNH6kBbm34Cld5meL6c6fYAXZWvkNNYQGqrGdQkpKAS5H9lP9cp8DjhsFT/0Vud7x6iQZz1y8t6cOfube0aCDcNBLeC2pNILDzUCawLhk4ngU4v8tt0lJWvGA2PEFvaVu2BR8FUvzFzBCwLSNdTsgdYlKBwMHyuN14tDoRTyLU+t/AddkW9raWruoq2bXrV3U6KdfE0Updk+SqCtQ9uI+yvTvl9fi2UXuy0fj0MZprbKjdt4s9YNH/GL8OsUb+9ndz6PMqaR2W1u2fQfXX/P1SH3PAFf4NgaOLo5nWgDrJ0lHOZ6EjqLJUCYRKgVBvZhAY3WwO/L8FBlLlCYdTYCBVnnA4BQZS5QmHU2AgVZ5wOAW9JUxESxkzB+bi4uLioh6qX40Oay2nphmdAAAAAElFTkSuQmCC">
            &nbsp; {{ download_form.name }}
          </span>
        </a>
      </div>
    </ng-container>
  </div>-->
</mat-nav-list>
