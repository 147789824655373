import { Vw_BusinessInformation } from './business/applicationlist';
import { BusinessLOB } from './business/business';
import { DebitDetails, RfPeriodCovered } from './treasury';

export interface BayadCenter {
  gatewayRefNumber: string;
  gatewayUrl: string;
}

export interface BayadCenterTransaction {
  id: number;
  gatewayTransactionID: number;
  amount: number;
  fee: number;
  status: string;
  datePaid: Date;
  createdAt: Date;
  paymentChannel: string;
  paymentChannelBranch: string;
}

export interface BusinessTransaction {
  id: number;
  transactionID: number;
  busID: string;
  busName: string;
  busOwner: string;
  busAddress: string;
  billYearFrom: number;
  billYearTo: number;
  periodFrom: number;
  periodTo: number;
  _PeriodFrom: RfPeriodCovered;
  _PeriodTo: RfPeriodCovered;
}

export interface Department {
  id: number;
  description: string;
  shortDescription: string;
}

export interface Gateway {
  id: number;
  description: string;
  acquiringBank: string;
  src: string;
  mdrAmount: number;
  mdrPercentage: number;
  icon: string;
}

export interface TransactionLocalStorage {
  refNumber: string;
  amount: number;
  gateway: string;
  busID: string;
  miscTransaction: MiscTransaction;
  rptTDNos: string[];
  payorName: string;
  expiry: number;
}

export function setTransactionLocalStorage(transaction: Transaction, payorName: string) {
  var d = new Date();
  var item: TransactionLocalStorage = {
    refNumber: transaction?.refNumber,
    amount: transaction?.totalAmount,
    gateway: transaction?.gateway?.description,
    busID: transaction?.businessTransaction?.busID,
    miscTransaction: transaction?.miscTransaction,
    rptTDNos: transaction?.realPropertyTransactions?.map(m => m.tdNo),
    payorName: payorName,
    expiry: d.setDate(d.getDate()+1),
  }
  var arr = _getlocalstoragearray();
  arr.push(item);
  _setlocalstoragearray(arr);
}

export function getTransactionLocalStorage(refNumber: string) {
  var arr = _getlocalstoragearray();
  return arr.find(r => r.refNumber == refNumber);
}

function _setlocalstoragearray(arr: TransactionLocalStorage[]) {
  localStorage.setItem(`TransactionLocalStorage`, JSON.stringify(arr));
}

function _getlocalstoragearray() {
  var arr: TransactionLocalStorage[];
  arr = JSON.parse(localStorage.getItem(`TransactionLocalStorage`)) ?? [];
  arr = arr.filter(r => r.expiry >= new Date().getTime());
  _setlocalstoragearray(arr);
  return arr;
}

export function computeMDR(gateway: Gateway, amount: number) {
  var percentageAmount = amount * (gateway.mdrPercentage ?? 0)
  return (gateway.mdrAmount ?? 0) + roundNumberAwayFromZero(percentageAmount, 2)
}

function roundNumberAwayFromZero(num: number, decimal: number) {
  return num < 0 ? -roundNumber(-num, decimal) : roundNumber(num, decimal);
}

function roundNumber(num: number, decimal: number) {
  return Math.round(num * Math.pow(10, decimal)) / Math.pow(10, decimal);
}

export interface GatewayTransaction {
  id: number;
  gatewayID: number;
  refNumber: string;
  gatewayRefNumber: string;
  amount: number;
  transactionFileID: number;
  transactionStatusID: number;
  createdBy: number;
  createdDate: Date;
  modifiedBy: number;
  modifiedDate: Date;
  postData: string;
  transaction: Transaction;
  transactionStatus: TransactionStatus;
  gateway: Gateway;
  bayadCenterTransaction: BayadCenterTransaction;
}

export interface Navigation {
  id: number;
  name: string;
  order: number;
  parentNavigationID: number;
  iconClass: string;
  createdDate: Date;
  modifiedDate: Date;
  parentNavigation: Navigation;
  childNavigation: Navigation[];
  pages: Page[];
}

export interface Page {
  id: number;
  pageName: string;
  isNavigationMenu: boolean;
  navigationID: number;
  url: string;
  area: string;
  controller: string;
  createdDate: Date;
  modifiedDate: Date;
  navigation: Navigation;
}

export interface Receipt {
  id: number;
  initialReceiptNo: number;
  endReceiptNo: number;
  currentReceiptNo: number;
  receiptStatusID: number;
  createdDate: Date;
  createdBy: number;
  modifiedDate: Date;
  modifiedBy: number;
  receiptStatus: ReceiptStatus;
}

export interface ReceiptStatus {
  id: number;
  description: string;
}

export interface ReconciledTransaction {
  id: number;
  transactionID: number;
  gatewayTransactionID: number;
  gatewayID: number;
  refNumber: string;
  amount: number;
  receiptNo: number;
  transactionStatusID: number;
  createdDate: Date;
  createdBy: number;
  modifiedDate: Date;
  modifiedBy: number;
  transactionStatus: TransactionStatus;
  gateway: Gateway;
  transaction: Transaction;
  gatewayTransaction: GatewayTransaction;
}

export interface Registration {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  gender: boolean;
  emailAddress: string;
  confirmEmailAddress: string;
  password: string;
  confirmPassword: string;
  mobileNumber: string;
  address: string;
  registrationCode: string;
  registrationStatus: RegistrationStatus;
}

export interface RegistrationStatus {
  id: number;
  description: string;
}

export interface Role {
  id: number;
  description: string;
  rolePages: RolePage[];
}

export interface RolePage {
  id: number;
  roleID: number;
  pageID: number;
  page: Page;
  create: number;
  read: number;
  update: number;
  delete: number;
  admin: number;
}

export interface Transaction {
  id: number;
  gatewayID: number;
  refNumber: string;
  checkoutID: string;
  paymentURL: string;
  amount: number;
  mdrAmount: number;
  mdrPercentage: number;
  totalMDR: number;
  totalAmount: number;
  validityDate: Date;
  createdDate: Date;
  createdBy: number;
  modifiedDate: Date;
  modifiedBy: number;
  transactionStatusID: number;
  transactionStatus: TransactionStatus;
  gateway: Gateway;
  businessTransaction: BusinessTransaction;
  miscTransaction: MiscTransaction;
  realPropertyTransactions: RealPropertyTransaction[];
  gatewayTransactions: GatewayTransaction[];
  postData: string;
}

export interface MiscTransaction {
  id: number;
  transactionID: number;
  receiptNumber: number;
  licensenumber: string;
  lastname: string;
  billSourceID: number;
  payorName: string;
  uovrNo: string;
}

export interface TransactionFile {
  id: number;
  gatewayID: number;
  path: string;
  fileName: string;
  remarks: string;
  transactionFileStatusID: number;
  createdBy: number;
  createdDate: Date;
  modifiedBy: number;
  modifiedDate: Date;
  gateway: Gateway;
  transactionFileStatus: TransactionFileStatus;
  file: File;
  validationmsg: string;
}

export interface TransactionFileStatus {
  id: number;
  description: string;
}

export interface TransactionStatus {
  id: number;
  description: string;
}

export interface User {
  id: number;
  username: string;
  password: string;
  emailAddress: string;
  prefixName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  mobileNumber: string;
  isVerifiedMobileNumber: boolean;
  userStatusID: number;
  createdDate: Date;
  modifiedDate: Date;
  userStatus: UserStatus;
  userRoles: UserRole[];
  userDepartments: UserDepartment[];
  userBusinesses: UserBusiness[];
  userRealProperties: UserRealProperty[];
}

export interface UserBusiness {
  id: number;
  userID: number;
  busID: string;
  busName: string;
  busOwner: string;
  busAddress: string;
  orNumber: number;
  amount: number;
  createdBy: number;
  createdDate: Date;
  modifiedBy: number;
  modifiedDate: Date;
  applications: Vw_BusinessInformation[];

  canRenew: boolean;
  hasUnpaidBills: boolean;
}

export interface UserRealProperty {
  id: number;
  userID: number;
  tdNo: string;
  owner: string;
  address: string;
  propertyAddress: string;
  property_Type: string;
  Classification: string;
  orNumber: number;
  amount: number;
  createdBy: number;
  createdDate: Date;
  modifiedBy: number;
  modifiedDate: Date;

  // For local use
  selected: boolean;
}
export interface RealPropertyTransaction {
  id: number;
  transactionID: number;
  tdNo: string;
  owner: string;
  address: string;
  propertyAddress: string;
  billYearFrom: number;
  billYearTo: number;
  quarterFrom: number;
  quarterTo: number;
  _QuarterFrom: RfPeriodCovered;
  _QuarterTo: RfPeriodCovered;
}

export interface UserDepartment {
  id: number;
  userID: number;
  departmentID: number;
  department: Department;
  isDepartmentHead: boolean;
}

export interface UserRole {
  id: number;
  userID: number;
  roleID: number;
  role: Role;
}

export interface UserStatus {
  id: number;
  description: string;
}

export interface BillByYear {
  year: number;
  debitDetailsPerQuarter: DebitDetails[];
}
