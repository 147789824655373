import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { HomeService } from 'src/app/_services/home.service';
import * as jwt_decode from "jwt-decode";
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuardMainService implements CanActivate {
  constructor(private router: Router, private homeService: HomeService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let usertoken = localStorage.getItem('currentUser')
    if (usertoken) {
      try {
        var usertokenDecoded = jwt_decode(usertoken);
        if (usertokenDecoded && usertokenDecoded.RefreshDate && new Date(usertokenDecoded.RefreshDate) <= new Date()) {
          this.homeService.refreshtoken()
            .pipe(first())
            .subscribe();
        }
      }
      catch (ex) {
        console.error('Error occurred in processing token:');
        console.error(ex);
      }
      return true;
    }

    this.router.navigate(['/Login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
