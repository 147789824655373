<mat-menu #navRef="matMenu">
  <ng-container *ngFor="let navigation of nav">
    <ng-container *ngIf="(navigation.childNavigation && navigation.childNavigation.length > 0) || (navigation.pages && navigation.pages.length > 0)">
      <button mat-menu-item [matMenuTriggerFor]="menu.childNavRef">{{navigation.name}}</button>
      <app-nav-menu-child #menu [nav]="navigation.childNavigation" [pages]="navigation.pages" (notifyParentEvent)="notifyParent($event)"></app-nav-menu-child>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="pages && pages.length > 0">
    <ng-container *ngFor="let page of pages">
      <a mat-menu-item [routerLink]="'/' + page.url.replace('Api/', '')" (click)="notifyParent()">{{page.pageName}}</a>
    </ng-container>
  </ng-container>
</mat-menu>
