import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class JwtInterceptorService implements HttpInterceptor {
    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let usertoken = localStorage.getItem('currentUser');
        if (usertoken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${usertoken}`
                }
            });
        }

        request = request.clone({ url: `Api/${request.url}` })

        if (environment.apiurl && environment.apiurl.length > 0) {
            request = request.clone({ url: `${environment.apiurl}/${request.url}` })
        }

        return next.handle(request);
    }
}
