import { Component } from '@angular/core';

@Component({
    selector: 'app-data-privacy',
    templateUrl: './data-privacy.component.html',
    styleUrls: ['./data-privacy.component.scss']
})
/** data.privacy component*/
export class DataPrivacyComponent {
    /** data.privacy ctor */
    constructor() {

    }
}