<div class="login">
  <div class="container">
    <div class="login-form mat-elevation-z2">
      <div class="row">
        <div class="login-column-header col-md-6">
          <div class="row">
            <div class="col-md-12 col-sm-4 col-xs-4 col-4">
              <div class="login-logo">
                <img src="/images/navotenoaako_logo.png" class="img-fluid" />
              </div>
            </div>
            <div class="col-md-12 col-sm-8 col-xs-8 col-8">
              <div class="login-logo-description">
                <img src="/images/online_services_and_description.png" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div class="login-column-content col-md-6">
          <h2 class="mat-headline mt-3 mb-4 font-weight-bold">Reset Password</h2>
          <small>Please remember your new password this time. Enter your new password below for your email:</small><br />
          <small class="font-weight-bold">{{ emailAddress }}</small>
          <!--New Password textbox-->
          <mat-form-field appearance="fill" class="mt-4 mb-2">
            <mat-label>Your New Password</mat-label>
            <input matInput [type]="hidepassword ? 'password' : 'text'" [(ngModel)]="password" required>
            <button type="button" mat-icon-button matSuffix (click)="hidepassword = !hidepassword" [attr.aria-label]="'Hide password'" tabindex="-1">
              <mat-icon>{{hidepassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error><b>New Password is required</b></mat-error>
          </mat-form-field>
          <!--Confirm New Password textbox-->
          <mat-form-field appearance="fill" class="mb-4">
            <mat-label>Confirm New Password</mat-label>
            <input matInput [type]="hideconfirmpassword ? 'password' : 'text'" [(ngModel)]="confirmpassword" required>
            <button type="button" mat-icon-button matSuffix (click)="hideconfirmpassword = !hideconfirmpassword" [attr.aria-label]="'Hide password'" tabindex="-1">
              <mat-icon>{{hideconfirmpassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error><b>Confirm New Password is required</b></mat-error>
          </mat-form-field>
          <div class="row">
            <div class="col-6">
              <button mat-button class="login-btn-back" (click)="backToSignIn()">Back to Log In</button>
            </div>
            <!--Confirm button-->
            <div class="col-6">
              <div class="text-right">
                <button mat-flat-button color="primary" (click)="confirm()">Reset</button>
              </div>
            </div>
          </div>
          <div class="loading-screen" *ngIf="isLoading">
            <div class="div-center">
              <mat-spinner></mat-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


