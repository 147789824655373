import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { HomeService } from '../_services/home.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardLoginService implements CanActivate {
  constructor(private router: Router, private homeService: HomeService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('currentUser')) {

      if (route.queryParams['registerSuccess'] == 'true') {
        this.homeService.logout();
        return true;
      }

      this.router.navigate(['/']);
      return false;
    }

    return true;
  }

}
