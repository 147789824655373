import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { NgxBarcodeModule } from 'ngx-barcode';
import { HelperService } from '../../_services/helper.service';
import { BarcodeComponent } from './barcode.component';

@NgModule({
  declarations: [BarcodeComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule, MatDialogModule, NgxBarcodeModule],
  providers: [HelperService]
})
export class BarcodeModule {
}
