<div class="payment-status payment-cancelled">
  <div class="container payment-summary-status">
    <div class="div-center">
      <div class="payment-summary-status-body">
        <mat-icon class="payment-icon cancelled">not_interested</mat-icon>
        <h1 class="payment-title mat-display-1 text-center" style="margin-bottom:28px;">Payment Cancelled</h1>
        <p class="mat-subheading-2 text-center mb-5" style="line-height:1.3">Payment has been cancelled successfully.</p>
        <a *ngIf="busid" mat-raised-button color="primary" class="btn-home" [routerLink]="'/Home'">Back to Payment Page</a>
        <a *ngIf="tdnos" mat-raised-button color="primary" class="btn-home" [routerLink]="'/RealProperty/Payment'">Back to Payment Page</a>
        <a *ngIf="receiptnumber" mat-raised-button color="primary" class="btn-home" [routerLink]="'/Ovr'">Back to Payment Page</a>
      </div>
    </div>
  </div>
</div>
