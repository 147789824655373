import { Component, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseClass } from '../base.class';

declare var require: any
const FileSaver = require('file-saver');

@Component({
    selector: 'app-downloadable-forms',
    templateUrl: './downloadable-forms.component.html',
    styleUrls: ['./downloadable-forms.component.scss']
})
/** downloadable.forms component*/
export class DownloadableFormsComponent extends BaseClass {
  

  /** downloadable.forms ctor */
  constructor(_inject: Injector) {
    super(_inject);
  }

  download(PDFUrl: string, PDFFilename: string) {
    FileSaver.saveAs(PDFUrl, PDFFilename);
    this.injector.get(MatSnackBar).open('Please wait while your form is downloading...', 'OK', {
      duration: 3000,
    });
  }
}
