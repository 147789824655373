<div style="height:50vh;width:300px;" id="img-viewer-loading-screen">
  <div class="div-center">
    <mat-spinner></mat-spinner>
  </div>
</div>
<pinch-zoom class="full-screen" id="img-viewer-body" style="display:none;">
  <img src="" id="img-viewer" />
</pinch-zoom>
<button color="primary" class="btn-clear-pinch-zoom" mat-mini-fab aria-label="Clear" style="position:fixed;top:3rem;right:3rem;z-index:6;display:none;" [mat-dialog-close]="true">
  <mat-icon>clear</mat-icon>
</button>
