
<div mat-dialog-content>
  <div class="downloadable-forms-body">
    <div class="row">
      <div class="col-sm-12 mb-3 mt-2 text-center">
        <h1 class="title"><img class="img-fluid icon" src="assets/icons/exclamation.png" /> IMPORTANT REMINDER</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 mb-3 text-center">
        <h1 class="description">FOR BUSINESS NEW/RENEWAL APPLICATION, PLEASE DOWNLOAD THE FILES BELOW AND ACCOMPLISH:</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12" style="position:relative;">
        <h1 class="description primary"><img class="img-fluid icon pdf" src="assets/icons/pdf.png" />&nbsp; AFFIDAVIT OF UNDERTAKING.PDF</h1><br />
        <button mat-button class="btn-action" (click)="download('assets/pdf/AFFIDAVIT-OF-UNDERTAKING.pdf', 'AFFIDAVIT OF UNDERTAKING')">DOWNLOAD</button>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-sm-12" style="position:relative;">
        <h1 class="description primary"><img class="img-fluid icon pdf" src="assets/icons/pdf.png" />&nbsp; AFFIDAVIT OF WAVER.PDF</h1><br />
        <button mat-button class="btn-action" (click)="download('assets/pdf/AFFIDAVIT-OF-WAVER.pdf', 'AFFIDAVIT OF WAVER')">DOWNLOAD</button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <h1 class="description highlight">NOTE:</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <h1 class="description">THESE DOCUMENTS MUST BE ACCOMPLISHED AND NOTARIZED BEFORE SENDING AS ATTACHMENTS.</h1>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true">Continue</button>
</div>
