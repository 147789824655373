<div class="payment-status payment-error">
  <div class="container payment-summary-status">
    <div class="div-center">
      <div class="payment-summary-status-body">
        <mat-icon class="payment-icon error">highlight_off</mat-icon>
        <h1 class="payment-title mat-display-1 text-center" style="margin-bottom:28px">Payment Error</h1>
        <p class="mat-body mb-2">An error occured while processing your payment:</p>
        <h1 class="mat-body mb-4">{{ msg }}</h1>
        <a mat-raised-button color="primary" class="btn-home" [routerLink]="'/'">Back to E-Services</a>
      </div>
    </div>
  </div>  
</div>

