import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Navigation, User, Registration, Page } from 'src/app/_models/system';
import { BusinessDownloadableForms } from '../_models/business/lookup';

@Injectable()
export class HomeService {
  constructor(private http: HttpClient) {
  }

  login(username: string, password: string, OTPCode?: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Basic ' + btoa(`${username}:${password}`)
      })
    };

    return this.http.post<{ loginsuccess: boolean, validationmsg: string, otpcreated: boolean, mobilenumber: string, token: any, resendtimeoutseconds: number, lockuntilseconds: number }>(`Home/Login`, { OTPCode }, httpOptions)
      .pipe(map(result => {
        if (result && result.token) {
          localStorage.setItem('currentUser', result.token);
        }
        return result;
      }));
  }

  refreshtoken() {
    return this.http.get<{ refreshsuccess: boolean, token: any }>('Home/RefreshToken')
      .pipe(map(result => {
        if (result && result.token) {
          localStorage.setItem('currentUser', result.token);
        }
        return result;
      }));
  }

  getNavigation() {
    return this.http.get<{ navigations: Navigation[], pages: Page[] }>('Home/GetNavigation')
  }

  profile() {
    return this.http.get<User>('Home/Profile')
  }

  changePassword(OldPassword: string, NewPassword: string) {
    return this.http.post<{ passwordchanged: boolean, validationmsg: string, token: any }>('Home/ChangePassword', { OldPassword, NewPassword })
      .pipe(map(result => {
        if (result && result.token) {
          localStorage.setItem('currentUser', result.token);
        }
        return result;
      }));
  }

  isOTPVerificationEnabled() {
    return this.http.get<boolean>('Home/IsOTPVerificationEnabled')
  }

  registerIsExistEmailAddress(Registration: Registration) {
    return this.http.post<boolean>('Home/RegisterCheckEmailAddress', { Registration });
  }

  registerCreateOTP(Registration: Registration, OTPCode: string) {
    return this.http.post<{ success: boolean, validationmsg: string }>('Home/RegisterCreateOTP', { Registration, OTPCode });
  }

  registerVerifyOTP(Registration: Registration, OTPCode: string) {
    return this.http.post<{ success: boolean, validationmsg: string }>('Home/RegisterVerifyOTP', { Registration, OTPCode });
  }

  register(Registration: Registration, OTPCode: string) {
    return this.http.post<{ success: boolean, invalidotpmobile: boolean, validationmsg: string }>('Home/Register', { Registration, OTPCode });
  }

  forgotPassword(EmailAddress: string) {
    return this.http.post<{ verified: boolean, validationmsg: string }>('Home/ForgotPassword', { EmailAddress })
  }

  forgotMobileNumber(EmailAddress: string, MobileNumber: string) {
    return this.http.post<{ verified: boolean, validationmsg: string }>('Home/ForgotMobileNumber', { EmailAddress, MobileNumber })
  }

  resetPassword(Code: string, NewPassword: string) {
    return this.http.post<{ verified: boolean, validationmsg: string }>('Home/ConfirmForgotPassword', { NewPassword, Code })
  }

  resetMobileNumber(Code: string, OTPCode: string) {
    return this.http.post<{ verified: boolean, validationmsg: string, otpcreated: boolean, resendtimeoutseconds: number }>('Home/ConfirmForgotMobileNumber', { OTPCode, Code })
  }

  logout() {
    localStorage.removeItem('currentUser');
  }

  getBusinessDownloadableForms() {
    return this.http.get<BusinessDownloadableForms[]>(`Home/GetBusinessDownloadableForms`);
  }

  getLatestClientVersion() {
    return this.http.get<number>(`Home/GetLatestClientVersion`);
  }
}
