import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../../imports/material.module';
import { PromptRemarksComponent } from './prompt-remarks.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [PromptRemarksComponent],
  imports: [CommonModule, FormsModule, MaterialModule],
  providers: []
})
export class PromptRemarksModule {
}
