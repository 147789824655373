import { Injectable, Injector } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { environment } from '../../environments/environment';
import { BaseClass } from '../base.class';

@Injectable()
export class HelperService extends BaseClass {
  constructor(_injector: Injector) {
    super(_injector);
  }

  getLookupGatewayImages() {
    return [
      {
        id: 1,
        image: "assets/gateways/bayad-center.png"
      },
      {
        id: 2,
        image: "assets/gateways/paymaya.png"
      },
      {
        id: 3,
        image: "assets/gateways/visa-mastercard.png"
      },
      {
        id: 4,
        image: "assets/gateways/landbank.png"
      },
      {
        id: 5,
        image: "assets/gateways/otc-unionbank.png"
      },
      {
        id: 6,
        image: "assets/gateways/gcash.png"
      },
      {
        id: 8,
        image: "assets/gateways/pisopay.png"
      },
      {
        id: 10,
        image: "assets/gateways/paymaya.png"
      }
    ];
  }

  getLookupTransactionStatusClasses() {
    return [
      {
        //Pending
        id: 1,
        class: "badge-pending"
      },
      {
        //Processing
        id: 2,
        class: "badge-processing"
      },
      {
        //Paid
        id: 3,
        class: "badge-paid"
      },
      {
        //Cancelled
        id: 4,
        class: "badge-cancelled"
      },
      {
        //For Refund
        id: 5,
        class: "badge-for-refund"
      },
      {
        //Refunded
        id: 6,
        class: "badge-refunded"
      },
      {
        //Verified
        id: 7,
        class: "badge-verified"
      },
      {
        //Unknown
        id: 8,
        class: "badge-dark"
      },
      {
        //Checkout
        id: 9,
        class: "badge-checkout"
      },
    ];
  }

  formatIntoTelephoneMobile(type, value) {
    var format = {
      one: "",
      two: "",
      three: "",
    }

    value = value.replace(/\D/g, "");
    if (type == 'telephone' && value != '' && value.length == 7) {
      format.one = value.substring(0, 3);
      format.two = value.substring(3, 5);
      format.three = value.substring(5, 7);
    }
    else if (type == 'mobile' && value != '' && value.length == 11) {
      format.one = value.substring(0, 4);
      format.two = value.substring(4, 7);
      format.three = value.substring(7, 11);
    }

    return format;
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n),
      extension = "";

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    if (mime != undefined) {
      extension = mime.split('/')[1];
    }

    return new File([u8arr], filename + (extension != "" ? "." + extension : ""), { type: mime });
  }

  formatBytes(a, b = 2) { if (0 === a) return "0 Bytes"; const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024)); return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d] }

  getFileExtension(filename: string) {
    return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
  }

  checkPasswordIfMatch(group: FormGroup) {
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : { notSame: true }
  }
  checkEmailAddressIfMatch(group: FormGroup) {
    let email = group.controls.emailAddress.value;
    let confirmEmail = group.controls.confirmEmailAddress.value;
    return email === confirmEmail ? null : { notSame: true }
  }

  // Toggle enable/disable clickable HTML Elements
  disableClickableHTMLElements(selectedDocument: Document, isDisabled: boolean) {
    let inputs: HTMLCollectionOf<HTMLInputElement> = selectedDocument.getElementsByTagName('input');
    let textareas: HTMLCollectionOf<HTMLTextAreaElement> = selectedDocument.getElementsByTagName('textarea');
    let dropdowns: HTMLCollectionOf<HTMLSelectElement> = selectedDocument.getElementsByTagName('select');
    let buttons: HTMLCollectionOf<HTMLButtonElement> = selectedDocument.getElementsByTagName('button');
    let links: HTMLCollectionOf<HTMLAnchorElement> = selectedDocument.getElementsByTagName('a');

    if (inputs.length > 0) {
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].disabled = isDisabled;
      }
    }

    if (textareas.length > 0) {
      for (let i = 0; i < textareas.length; i++) {
        textareas[i].disabled = isDisabled;
      }
    }

    if (dropdowns.length > 0) {
      for (let i = 0; i < dropdowns.length; i++) {
        dropdowns[i].disabled = isDisabled;
      }
    }

    if (buttons.length > 0) {
      for (let i = 0; i < buttons.length; i++) {
        buttons[i].disabled = isDisabled;
      }
    }

    if (links.length > 0) {
      for (let i = 0; i < links.length; i++) {
        if (isDisabled) {
          links[i].classList.add('pointer-events-none');
          links[i].tabIndex = -1;
        }
        else {
          links[i].classList.remove('pointer-events-none');
          links[i].removeAttribute('tabindex');
        }
      }
    }
  }

  distinctByProperties<T>(arr: T[], props: (keyof T)[]): T[] {
    const seen: { [key: string]: boolean } = {};
    return arr.filter((item) => {
      const key = props.map(prop => String(item[prop])).join('|');
      if (seen[key]) {
        return false;
      }
      seen[key] = true;
      return true;
    });
  }

  validateTin(control: FormControl) {
    const value = control.value;
    let newValue = value?.split("_").join("");

    if (newValue && newValue.length !== 17) {
      return { invalidLength: true };
    }
    return null;
  }

  formatTin(value: string) {
    if (value) {
      const trimmedValue = value.trim();

      // Trimmed TIN value must have a minimum length of 11 characters e.g. "000-000-000" and not exceeding to 17 characters e.g. "000-000-000-00000"
      if (trimmedValue.length > 0 && trimmedValue.length >= 11 && trimmedValue.length <= 17) {
        const splittedValue = trimmedValue.split('-'); // Split the trimmed value
        const lastTinValue = splittedValue[splittedValue.length - 1]; // Get the last split value of TIN

        // Checks if the last TIN are numbers
        if (!isNaN(+lastTinValue)) {
          // If the TIN have no branch code, add only the default format of TIN branch code to the last of TIN
          if (splittedValue.length === 3) {
            return trimmedValue + "-00000";
          }

          if (lastTinValue.length <= 5) {
            //Return the formatted value where the last TIN value have a padstart of "0"
            return trimmedValue.substring(0, 12) + lastTinValue.padStart(5, "0");
          }
        }
      }
    }
    return null;
  }
}

export class InputErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}
