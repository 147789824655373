import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { WebcamModule } from 'ngx-webcam';
import { NgxBarcodeModule } from 'ngx-barcode';
import { BarcodeModule } from '../shared/barcode/barcode.module';
import { MatStepperModule } from '@angular/material/stepper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatTreeModule } from '@angular/material/tree';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
    imports: [
      MatInputModule,
      MatMenuModule, MatButtonModule, MatProgressBarModule, MatToolbarModule, MatIconModule, MatFormFieldModule, MatCardModule, MatStepperModule,
      MatTableModule, MatPaginatorModule, MatCheckboxModule, MatRadioModule, MatSnackBarModule, MatTabsModule, MatDialogModule, MatSelectModule, MatSlideToggleModule,
      MatProgressSpinnerModule, MatTooltipModule, MatDatepickerModule, MatNativeDateModule, MatListModule, CurrencyMaskModule, PinchZoomModule, WebcamModule, NgxBarcodeModule, BarcodeModule,
      MatAutocompleteModule, MatChipsModule, MatTreeModule, MatBadgeModule, MatBottomSheetModule, MatButtonToggleModule,MatExpansionModule
    ],
    exports: [
      MatInputModule,
      MatMenuModule, MatButtonModule, MatProgressBarModule, MatToolbarModule, MatIconModule, MatFormFieldModule, MatCardModule, MatStepperModule,
      MatTableModule, MatPaginatorModule, MatCheckboxModule, MatRadioModule, MatSnackBarModule, MatTabsModule, MatDialogModule, MatSelectModule, MatSlideToggleModule,
      MatProgressSpinnerModule, MatTooltipModule, MatSidenavModule, MatDatepickerModule, MatNativeDateModule, MatListModule, CurrencyMaskModule, PinchZoomModule, WebcamModule, NgxBarcodeModule, BarcodeModule,
      MatAutocompleteModule, MatChipsModule, MatTreeModule, MatBadgeModule, MatBottomSheetModule, MatButtonToggleModule, MatExpansionModule
    ]
})
export class MaterialModule { 
}
