import { AfterViewInit, Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { BaseClass } from '../base.class';
import { Registration } from '../_models/system';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HomeService } from 'src/app/_services/home.service';
import { debounceTime, first } from 'rxjs/operators';
import { LoginRegistrationMobilenumberComponent } from './login-registration-mobilenumber.component';
import { HelperService, InputErrorStateMatcher } from '../_services/helper.service';
import { MatButton } from '@angular/material/button';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-login-registration',
  templateUrl: './login-registration.component.html',
  styleUrls: ['./login-registration.component.scss']
})
/** login.registration component*/
export class LoginRegistrationComponent extends BaseClass implements AfterViewInit {
  registration: Registration;
  isOTPVerificationEnabled: boolean = true;
  otpCode: string;
  verifiedMobileNumber: string;
  hidepassword: boolean = true;
  hideconfirmpassword: boolean = true;
  agreed: boolean = false;

  isLoading: boolean = false;

  formgroup_register: FormGroup;
  errorMatcher = new InputErrorStateMatcher();

  isEmailAddressCleared: boolean = false;
  @ViewChild('emailAddressInput') emailAddressInput: ElementRef;

  /** login.registration ctor */
  constructor(_injector: Injector, private formBuilder: FormBuilder) {
    super(_injector);

    this.formgroup_register = this.formBuilder.group({
      firstName: [''],
      middleName: [''],
      lastName: [''],
      mobileNumber: [''],
      emailAddressFormGroup: this.formBuilder.group({
        emailAddress: ['', Validators.email],
        confirmEmailAddress: ['', Validators.email]
      }, { validator: this.injector.get(HelperService).checkEmailAddressIfMatch }),
      passwordFormGroup: this.formBuilder.group({
        password: ['', Validators.pattern('[\x00-\x7F]+')],
        confirmPassword: ['', Validators.pattern('[\x00-\x7F]+')]
      }, { validator: this.injector.get(HelperService).checkPasswordIfMatch })
    });

    this.injector.get(HomeService).isOTPVerificationEnabled()
      .pipe(first())
      .subscribe(
        retval => {
          this.isOTPVerificationEnabled = retval;
        }
    );
  }

  ngAfterViewInit() {
    fromEvent(this.emailAddressInput.nativeElement, 'keyup').pipe(debounceTime(500))
      .subscribe(async (event: any) => {
        this.checkEmailAddress();
      });
  }

  verifyMobile() {
    this.registration = <Registration>this.formgroup_register.value;

    if (this.registration.mobileNumber.length == 11) {
      this.injector.get(HomeService).registerCreateOTP(this.registration, this.otpCode)
        .pipe(first())
        .subscribe(
          retval => {
            if (retval) {
              if (retval.success) {
                const dialogRef = this.injector.get(MatDialog).open(LoginRegistrationMobilenumberComponent, {
                  data: { registration: this.registration }
                })

                dialogRef.afterClosed().subscribe(retval => {
                  if (retval) {
                    this.otpCode = retval;
                    this.verifiedMobileNumber = this.registration.mobileNumber;
                  }
                })
              }
              else {
                this.injector.get(MatSnackBar).open(retval.validationmsg || 'Something went wrong', 'OK', {
                  duration: 2000,
                });
              }
            }
            else {
              this.injector.get(MatSnackBar).open('Something went wrong', 'OK', {
                duration: 2000,
              });
            }
          }
      );
    }
    else {
      this.injector.get(MatSnackBar).open('Mobile no. must be equal to 11 digits.', 'OK', {
        duration: 2000,
      });
    }
  }

  checkEmailAddress() {
    this.registration = <Registration>this.formgroup_register.value;
    this.registration.emailAddress = this.formgroup_register.get('emailAddressFormGroup.emailAddress').value;

    this.injector.get(HomeService).registerIsExistEmailAddress(this.registration)
      .pipe(first())
      .subscribe(
        retval => {
          if (retval) {
            this.formgroup_register.get('emailAddressFormGroup.emailAddress').setErrors({ alreadyExist: true });
          }

          if (!this.formgroup_register.get('emailAddressFormGroup.emailAddress').hasError('required') && !this.formgroup_register.get('emailAddressFormGroup.emailAddress').hasError('email') && !this.formgroup_register.get('emailAddressFormGroup.emailAddress')?.hasError('alreadyExist')) {
            this.isEmailAddressCleared = true;
          }
          else {
            this.isEmailAddressCleared = false;
          }
          
          this.formgroup_register.get('emailAddressFormGroup.emailAddress').markAsTouched();
        }
    );
  }

  create() {
    this.registration = <Registration>this.formgroup_register.value;

    if (this.registration) {
      this.registration.emailAddress = this.formgroup_register.get('emailAddressFormGroup.emailAddress').value;
      this.registration.confirmEmailAddress = this.formgroup_register.get('emailAddressFormGroup.confirmEmailAddress').value;
      this.registration.password = this.formgroup_register.get('passwordFormGroup.password').value;
      this.registration.confirmPassword = this.formgroup_register.get('passwordFormGroup.confirmPassword').value;

      if (this.registration.mobileNumber.length == 11) {
        if (this.registration.mobileNumber == this.verifiedMobileNumber || !this.isOTPVerificationEnabled) {
          if (this.registration.emailAddress == this.registration.confirmEmailAddress) {
            if (this.registration.password == this.registration.confirmPassword) {
              if (this.registration.password.length > 4) {
                this.injector.get(HelperService).disableClickableHTMLElements(document, true);
                this.isLoading = true;

                setTimeout(() => {
                  this.injector.get(HomeService).register(this.registration, this.otpCode)
                    .pipe(first())
                    .subscribe(
                      retval => {
                        this.injector.get(HelperService).disableClickableHTMLElements(document, false);
                        this.isLoading = false;
                        if (retval.success) {
                          this.injector.get(MatSnackBar).open("We've sent an email to " + this.registration.emailAddress + " for verification.", 'OK', {
                            duration: 0,
                          });
                          this.injector.get(Router).navigate(['/Login']);
                        }
                        else {
                          this.injector.get(MatSnackBar).open(retval.validationmsg || 'Something went wrong', 'OK', {
                            duration: 2000,
                          });
                        }
                        if (retval.invalidotpmobile) {
                          this.verifiedMobileNumber = undefined;
                        }
                      },
                      error => {
                        this.injector.get(HelperService).disableClickableHTMLElements(document, false);
                        this.isLoading = false;
                      }
                    );
                }, 1000);
              }
              else {
                this.injector.get(MatSnackBar).open('The password must be at least 5 characters.', 'OK', {
                  duration: 3000,
                });
              }
            }
            else {
              this.injector.get(MatSnackBar).open('The password and confirmation password do not match.', 'OK', {
                duration: 3000,
              });
            }
          }
          else {
            this.injector.get(MatSnackBar).open('The email and confirmation email do not match.', 'OK', {
              duration: 3000,
            });
          }
        }
        else {
          this.verifyMobile();
        }
      }
      else {
        this.injector.get(MatSnackBar).open('Mobile no. must be equal to 11 digits.', 'OK', {
          duration: 2000,
        });
      }
    }
  }

  backToSignIn() {
    this.injector.get(Router).navigate(['/Login']);
  }
}
