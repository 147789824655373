<div class="row">
  <div class="col-6">
    <h1 mat-dialog-title>Transaction Details</h1>
  </div>
  <div class="col-6">
    <div class="text-right mt-1">
      <label [attr.class]="'badge badge-' + statuscolor">{{ data.transactionStatus.description }}</label>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <dl class="row mb-0">
    <dt class="col-sm-12 col-md-3">
      <label class="mat-body mb-0">Business ID</label>
    </dt>
    <dd class="col-sm-12 col-md-9">
      <label class="mat-body mb-0">{{ data.businessTransaction.busID }}</label>
    </dd>
  </dl>
  <dl class="row mb-0">
    <dt class="col-sm-12 col-md-3">
      <label class="mat-body mb-0">Reference No.</label>
    </dt>
    <dd class="col-sm-12 col-md-9">
      <label class="mat-body mb-0">{{ data.refNumber }}</label>
    </dd>
  </dl>
  <dl class="row mb-0">
    <dt class="col-sm-12 col-md-3">
      <label class="mat-body mb-0">Bill From &minus; To</label>
    </dt>
    <dd class="col-sm-12 col-md-9">
      <label class="mat-body mb-0">{{ data.businessTransaction?._PeriodFrom?.periodCovered  + ' ' + data.businessTransaction.billYearFrom }} &minus; {{ data.businessTransaction?._PeriodTo?.periodCovered + ' ' + data.businessTransaction?.billYearTo }}</label>
    </dd>
  </dl>
  <dl class="row mb-0">
    <dt class="col-sm-12 col-md-3">
      <label class="mat-body mb-0">Payment Mode</label>
    </dt>
    <dd class="col-sm-12 col-md-9">
      <label class="mat-body mb-0">{{ data.gateway.description }}</label>
    </dd>
  </dl>
  <dl class="row mb-0">
    <dt class="col-sm-12 col-md-3">
      <label class="mat-body mb-0">Amount</label>
    </dt>
    <dd class="col-sm-12 col-md-9">
      <label class="mat-body mb-0">{{ data.amount | currency:'&#8369; ' }}</label>
    </dd>
  </dl>
  <dl class="row mb-0">
    <dt class="col-sm-12 col-md-3">
      <label class="mat-body mb-0">Convenience Fee</label>
    </dt>
    <dd class="col-sm-12 col-md-9">
      <label class="mat-body mb-0">{{ data.totalMDR ? (data.totalMDR | currency:'&#8369; ') : '-' }}</label>
    </dd>
  </dl>
  <dl class="row mb-0">
    <dt class="col-sm-12 col-md-3">
      <label class="mat-body mb-0">Total Amount</label>
    </dt>
    <dd class="col-sm-12 col-md-9">
      <label class="mat-body mb-0">{{ data.totalAmount ? (data.totalAmount | currency:'&#8369; ') : '-' }}</label>
    </dd>
  </dl>
  <dl class="row mb-0">
    <dt class="col-sm-12 col-md-3">
      <label class="mat-body mb-0">Created Date</label>
    </dt>
    <dd class="col-sm-12 col-md-9">
      <label class="mat-body mb-0">{{ data.createdDate | date: 'MMM dd, y hh:mm:ss a' }}</label>
    </dd>
  </dl>
  <dl *ngIf="gatewaytransaction" class="row mb-0">
    <dt class="col-sm-12 col-md-3">
      <label class="mat-body mb-0">Gateway Reference No.</label>
    </dt>
    <dd class="col-sm-12 col-md-9">
      <label class="mat-body mb-0">{{ gatewaytransaction.gatewayRefNumber }}</label>
    </dd>
  </dl>
  <div class="row" *ngIf="printdata">
    <div class="col-md-12">
      <div class="table-container">
        <table class="table-sticky" mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let row">{{ row.description }}</td>
            <td mat-footer-cell *matFooterCellDef style="font-weight:bold">Total</td>
          </ng-container>
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td mat-cell *matCellDef="let row">{{ row.amount | currency:'&#8369; ' }}</td>
            <td mat-footer-cell *matFooterCellDef style="font-weight:bold">{{ amount() | currency:'&#8369; ' }}</td>
          </ng-container>
          <ng-container matColumnDef="surcharge">
            <th mat-header-cell *matHeaderCellDef>Surcharge</th>
            <td mat-cell *matCellDef="let row">{{ row.surcharge | currency:'&#8369; ' }}</td>
            <td mat-footer-cell *matFooterCellDef style="font-weight:bold">{{ surcharge() | currency:'&#8369; ' }}</td>
          </ng-container>
          <ng-container matColumnDef="interest">
            <th mat-header-cell *matHeaderCellDef>Interest</th>
            <td mat-cell *matCellDef="let row">{{ row.interest | currency:'&#8369; ' }}</td>
            <td mat-footer-cell *matFooterCellDef style="font-weight:bold">{{ interest() | currency:'&#8369; ' }}</td>
          </ng-container>
          <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef>Total</th>
            <td mat-cell *matCellDef="let row">{{ row.amount + row.surcharge + row.interest | currency:'&#8369; ' }}</td>
            <td mat-footer-cell *matFooterCellDef style="font-weight:bold">{{ total() | currency:'&#8369; ' }}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button *ngIf="data.gatewayID == null && data.transactionStatusID == 1" (click)="viewbarcode()" class="mr-3">View Bar Code</button>
  <button mat-button [mat-dialog-close] class="mr-3">Close</button>
</div>
