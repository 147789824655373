<div class="login">
  <div class="container">
    <div class="login-form mat-elevation-z2">
      <div class="row">
        <div class="login-column-header col-md-6">
          <div class="row">
            <div class="col-md-12 col-sm-4 col-xs-4 col-4">
              <div class="login-logo">
                <img src="/images/navotenoaako_logo.png" class="img-fluid" />
              </div>
            </div>
            <div class="col-md-12 col-sm-8 col-xs-8 col-8">
              <div class="login-logo-description">
                <img src="/images/online_services_and_description.png" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div class="login-column-content col-md-6">
          <div class="login-menu-option online-business-and-payment" [routerLink]="['/Home']" title="Click here to proceed to Online Business Application and Online Payment portal">
            <div class="row">
              <div class="col-3 text-center">
                <div class="login-menu-option-logo">
                  <img src="/e-services/business-online.png" class="img-fluid" />
                </div>
              </div>
              <div class="col-7">
                <h2 class="login-menu-option-title mat-title mb-0">Online Business Application and Online Payment</h2>
                <p class="login-menu-option-description mat-caption">Need to register or renew your business, or pay your business taxes? Click here.</p>
              </div>
              <div class="col-2">
                <div class="login-menu-option-arrow">
                  <mat-icon>chevron_right</mat-icon>
                </div>
              </div>
            </div>
          </div>
          <div class="login-menu-option ordinance-violation-receipt" [routerLink]="['/Ovr']" title="Click here to proceed to Ordinance Violation Receipt portal">
            <div class="row">
              <div class="col-3 text-center">
                <div class="login-menu-option-logo">
                  <img src="/e-services/ordinance-violation-receipt.png" class="img-fluid" />
                </div>
              </div>
              <div class="col-7">
                <h2 class="login-menu-option-title mat-title mb-0">Ordinance Violation Receipt</h2>
                <p class="login-menu-option-description mat-caption">Need to settle your violation/s? Click here.</p>
              </div>
              <div class="col-2">
                <div class="login-menu-option-arrow">
                  <mat-icon>chevron_right</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Landing page footer-->
    <div class="login-footer">
      <div class="row">
        <div class="col-md-12 mb-2 login-footer-column-left-section">
          <div class="row">
            <div class="col-md-12 text-center">
              <div class="login-footer-title-description">
                <p class="login-footer-title-description-header mat-subheading-2">CITY GOVERNMENT OF NAVOTAS</p>
                <p class="login-footer-title-description-subhead mat-subheading-2">If you have any questions or issues, please contact us at <b>(+632) 8-283-7415.</b></p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 mb-3 text-center login-footer-column-right-section">
          <div class="login-footer-link mr-3" title="Go to https://twitter.com/Navotas_City">
            <a href="https://twitter.com/Navotas_City"><img src="/images/twitter.png" class="img-fluid" /></a>
          </div>
          <div class="login-footer-link mr-3" title="Go to https://www.facebook.com/navotenoako">
            <a href="https://www.facebook.com/navotenoako"><img src="/images/facebook.png" class="img-fluid" /></a>
          </div>
          <div class="login-footer-link" title="Go to https://www.instagram.com/jrtiangco/">
            <a href="https://www.instagram.com/jrtiangco/"><img src="/images/instagram.png" class="img-fluid" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
