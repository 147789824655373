<div class="container">
  <mat-card>
    <div class="row">
      <div class="col-md-3">
        <mat-form-field>
          <input readonly matInput placeholder="Prefix" [(ngModel)]="data.prefixName" class="text-uppercase">
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <input readonly matInput placeholder="First Name" [(ngModel)]="data.firstName" class="text-uppercase">
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <input readonly matInput placeholder="Middle Name" [(ngModel)]="data.middleName" class="text-uppercase">
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <input readonly matInput placeholder="Last Name" [(ngModel)]="data.lastName" class="text-uppercase">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <mat-form-field>
          <input readonly matInput placeholder="Mobile Number" [(ngModel)]="data.mobileNumber">
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <input readonly matInput placeholder="User Name" [(ngModel)]="data.username">
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field>
          <input readonly matInput placeholder="Password" value="*********">
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <button mat-stroked-button color="primary" (click)="changePasswordDialog()" class="mt-2">Change Password</button>
      </div>
    </div>
  </mat-card>
</div>
