import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { HomeService } from 'src/app/_services/home.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardUpdateClientVersion implements CanActivate {
  constructor(private router: Router, private homeService: HomeService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Reload the page if the current version did not match from the server version
    this.homeService.getLatestClientVersion().toPromise().then(latestClientVersion => {
      const currentClientVersion = Number(window.localStorage.getItem('clientVersion'));
      latestClientVersion = isNaN(latestClientVersion) ? 0 : latestClientVersion;

      if (currentClientVersion === 0 || currentClientVersion !== latestClientVersion) {
        window.localStorage.setItem('clientVersion', latestClientVersion?.toString() ?? '0');
        window.location.reload();
      }
    });
    
    return true;
  }
}
