<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <mat-form-field >
    <mat-label>{{ data.inputLabel }}</mat-label>
    <textarea matInput [(ngModel)]="remarks" rows="5" placeholder="Enter here..." class="text-uppercase"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close] class="mr-1">Cancel</button>
  <button mat-flat-button color="primary" (click)="proceed()">Confirm</button>
</div>
