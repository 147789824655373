import { Component, Injector, Inject } from '@angular/core';
import { BaseClass } from '../base.class';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Bill, BillDetail } from '../_models/treasury';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-home-business-billdetails',
  templateUrl: './home-business-billdetails.component.html',
  styleUrls: ['./home-business-billdetails.component.scss']
})
/** home-business-billdetails component*/
export class HomeBusinessBilldetailsComponent extends BaseClass {

  displayedColumns: string[] = ['description', 'total', 'amount', 'surcharge', 'interest'];
  dataSource: MatTableDataSource<BillDetail>;

  /** home-business-billdetails ctor */
  constructor(_injector: Injector, private dialogRef: MatDialogRef<HomeBusinessBilldetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: Bill) {
    super(_injector);

    if (data.cedula) {
      var arr = <BillDetail[]>[];
      arr.push(<BillDetail>{
        description: `Cedula (${data.cedula.cedulaType})`,
        amount: data.cedula.amount,
        surcharge: 0,
        interest: data.cedula.interest,
      });
      this.dataSource = new MatTableDataSource<BillDetail>(arr.concat(data.billDetails));
    }
    else {
      this.dataSource = new MatTableDataSource<BillDetail>(data.billDetails);
    }
  }

  amount() {
    return (this.dataSource && this.dataSource.data.map(d => d.amount).reduce((x, y) => x + y, 0)) ?? 0
  }

  surcharge() {
    return (this.dataSource && this.dataSource.data.map(d => d.surcharge).reduce((x, y) => x + y, 0)) ?? 0
  }

  interest() {
    return (this.dataSource && this.dataSource.data.map(d => d.interest).reduce((x, y) => x + y, 0)) ?? 0
  }

  total() {
    return (this.dataSource && this.dataSource.data.map(d => d.amount + d.surcharge + d.interest).reduce((x, y) => x + y, 0)) ?? 0
  }
}
