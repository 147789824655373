<h1 mat-dialog-title>Payment Summary</h1>
<div mat-dialog-content>
  <div class="payment-summary-details">
    <dl class="payment-summary-details-item row">
      <dt class="col-4">
        <p class="payment-summary-details-item-label mat-caption">Business ID</p>
      </dt>
      <dd class="col-8 mb-0">
        <p class="payment-summary-details-item-description mat-caption">{{ data.selectedBusID.busID | uppercase }}</p>
      </dd>
    </dl>
    <hr />
    <dl class="payment-summary-details-item row">
      <dt class="col-4">
        <p class="payment-summary-details-item-label mat-caption">Business Name</p>
      </dt>
      <dd class="col-8 mb-0">
        <p class="payment-summary-details-item-description mat-caption">{{ data.selectedBusID.busName | uppercase }}</p>
      </dd>
    </dl>
    <ng-container *ngIf="selectedGateway != undefined && computeMDR(selectedGateway, data.billTotalAmount) > 0">
      <hr />
      <dl class="payment-summary-details-item row">
        <dt class="col-4">
          <p class="payment-summary-details-item-label mat-caption text-warning">Convenience Fee</p>
        </dt>
        <dd class="col-8 mb-0">
          <p class="payment-summary-details-item-description mat-caption">
            {{ computeMDR(selectedGateway, data.billTotalAmount) | currency:'&#8369; ' }}
          </p>
        </dd>
      </dl>
    </ng-container>
    <hr />
    <dl class="payment-summary-details-item row">
      <dt class="col-4">
        <p class="payment-summary-details-item-label label-title mat-body">Total Amount</p>
      </dt>
      <dd class="col-8 mb-0">
        <p class="payment-summary-details-item-total-amount mat-headline">{{ computedTotal | currency:'&#8369; ' }}</p>
      </dd>
    </dl>
  </div>
  <h1 class="mat-body mb-0" style="font-weight:500;">Payment Mode</h1>
  <p class="mat-caption">Please choose a gateway below to proceed.</p>
  <div *ngIf="loadPaymentModes == false && gateways != null && gateways.length > 0" class="row">
    <ng-container *ngFor="let gateway of gateways">
      <div *ngIf="gateway?.id > 0" class="col-sm-4 col-6 mb-3">
        <div class="payment-method-option" [id]="gateway.id" (click)="selectPaymentMode(gateway.id)" [attr.data-mdr-amount]="computeMDR(gateway, data.billTotalAmount) | currency:'&#8369; '">
          <img class="img-fluid" [src]="getGatewayImage(gateway.id) != null ? getImage : 'assets/gateways/default-gateway.png'" />
          <mat-icon class="selected-icon">check_circle</mat-icon>
        </div>
        <h2 class="payment-mode-description mat-small">{{ gateway.description }}</h2>
      </div>
      <div *ngIf="gateway == null || gateway.id == null" class="col-sm-3 col-6 mb-3">
        <div class="payment-method-option" id="-1" (click)="selectPaymentMode(-1)">
          <img class="img-fluid" src="assets/gateways/default-gateway.png" />
          <mat-icon class="selected-icon">check_circle</mat-icon>
        </div>
        <h2 class="payment-mode-description mat-small">{{ gateway.description }}</h2>
      </div>
    </ng-container>
  </div>
  <div *ngIf="loadPaymentModes && gateways == null || gateways.length == 0" class="row">
    <div class="col-md-12">
      <mat-spinner [diameter]="50" style="margin: 0 auto;"></mat-spinner>
    </div>
  </div>
</div>
<div mat-dialog-actions>
    <button mat-button class="mr-3" [mat-dialog-close]>Cancel</button>
    <button mat-flat-button color="primary" (click)="confirmPayment()">Proceed</button>
</div>
<div class="loading-screen" *ngIf="isLoading">
  <div class="div-center">
    <mat-spinner></mat-spinner>
  </div>
</div>
<form ngNoForm hidden [target]="target" method="post" [action]="posturl">
  <ng-container *ngFor="let pd of postdata | keyvalue">
    <input type="hidden" [name]="pd.key" [value]="pd.value"/>
  </ng-container>
  <button #btnform>test</button>
</form>
