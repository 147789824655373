import { Component, Injector, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BaseClass } from '../base.class';
import { Transaction, GatewayTransaction } from '../_models/system';
import { PaymentService } from '../_services/businesspermit.service';
import { first } from 'rxjs/operators';
import { PrintData, BillDetail } from '../_models/treasury';
import { MatTableDataSource } from '@angular/material/table';
import { BarcodeComponent } from '../shared/barcode/barcode.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PromptMessageComponent } from '../shared/prompt-message.component';

@Component({
    selector: 'app-home-transaction-view',
    templateUrl: './home-transaction-view.component.html',
    styleUrls: ['./home-transaction-view.component.scss']
})
/** home.transaction.view component*/
export class HomeTransactionViewComponent extends BaseClass {

  displayedColumns: string[] = ['description', 'amount', 'surcharge', 'interest', 'total'];
  dataSource: MatTableDataSource<BillDetail>;

  gatewaytransaction: GatewayTransaction;
  printdata: PrintData;

  statuscolor: string = "dark";

    /** home.transaction.view ctor */
  constructor(_injector: Injector, private dialogRef: MatDialogRef<HomeTransactionViewComponent>, @Inject(MAT_DIALOG_DATA) public data: Transaction) {
    super(_injector);

    if (data.transactionStatus.id == 1) {
      this.statuscolor = "pending";
    }
    else if (data.transactionStatus.id == 2) {
      this.statuscolor = "for-verification";
    }
    else if (data.transactionStatus.id == 3) {
      this.statuscolor = "paid";
    }
    else if (data.transactionStatus.id == 4) {
      this.statuscolor = "cancelled";
    }
    else if (data.transactionStatus.id == 5) {
      this.statuscolor = "for-refund";
    }
    else if (data.transactionStatus.id == 6) {
      this.statuscolor = "refunded";
    }
    else if (data.transactionStatus.id == 7) {
      this.statuscolor = "verified";
    }
    else if (data.transactionStatus.id == 9) {
      this.statuscolor = "checkout";
    }
    else {
      this.statuscolor = "dark";
    }

    this.injector.get(PaymentService).viewPayment(data.refNumber, data.businessTransaction.busID)
      .pipe(first())
      .subscribe(
        data => {
          this.printdata = data.printdata
          this.gatewaytransaction = data.gatewaytransaction;
          if (this.printdata) {
            if (this.printdata.cedula) {
              var arr = <BillDetail[]>[];
              arr.push(<BillDetail>{
                description: `Cedula (${this.printdata.cedula.cedulaType})`,
                amount: this.printdata.cedula.amount,
                surcharge: 0,
                interest: this.printdata.cedula.interest,
              });
              this.dataSource = new MatTableDataSource<BillDetail>(arr.concat(this.printdata.billDetails));
            }
            else {
              this.dataSource = new MatTableDataSource<BillDetail>(this.printdata.billDetails);
            }
          }
        }
      )

  }

  viewbarcode() {
    this.injector.get(MatDialog).open(BarcodeComponent, {
      disableClose: true,
      width: '320px',
      data: { transaction: this.data }
    });
  }

  amount() {
    return (this.dataSource && this.dataSource.data.map(d => d.amount).reduce((x, y) => x + y, 0)) ?? 0
  }

  surcharge() {
    return (this.dataSource && this.dataSource.data.map(d => d.surcharge).reduce((x, y) => x + y, 0)) ?? 0
  }

  interest() {
    return (this.dataSource && this.dataSource.data.map(d => d.interest).reduce((x, y) => x + y, 0)) ?? 0
  }

  total() {
    return (this.dataSource && this.dataSource.data.map(d => d.amount + d.surcharge + d.interest).reduce((x, y) => x + y, 0)) ?? 0
  }
}
