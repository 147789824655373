<div mat-dialog-content>
  <div class="mat-dialog-header">
    <div class="mat-dialog-header-icon">
      <ng-container *ngIf="data.icon">
        <mat-icon inline="true">{{ data.icon }}</mat-icon><br />
        <h2 class="mat-headline">{{ data.title }}</h2>
      </ng-container>
      <ng-container *ngIf="data.icon == undefined">
        <br />
        <h2 class="mat-headline mat-dialog-header-no-icon">{{ data.title }}</h2>
      </ng-container>

    </div>
  </div>
  <ng-container *ngIf="data.type == 'checkfields'">
    <ng-container *ngIf="errors.length > 0">
      <div *ngFor="let error of errors" class="error-bullet">
        <h2 class="error-bullet-icon">&#9679;</h2>
        <h2 class="error-bullet-description mat-body">{{ error }}</h2>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="data.type == 'success'">
    <label>Click 'OK' to proceed. Thank you.</label>
  </ng-container>
  <ng-container *ngIf="data.type == 'error' || data.type == 'info'">
    <p class="mat-subheading-1" style="word-break:break-word">{{ data.items }}</p>
  </ng-container>
  <ng-container *ngIf="data.type == 'html'">
    <div [innerHTML]="data.items"></div>
  </ng-container>
</div>  
<div mat-dialog-actions>
  <button mat-flat-button cdkFocusInitial (click)="ok()">OK</button>
</div>
