<h1 mat-dialog-title>Bill Details</h1>
<div mat-dialog-content>
  <p class="mat-body mobile-text-info">Please scroll to the right to see more details.</p>
  <div class="table-container">
    <table class="table-sticky" mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let row">{{ row.description }}</td>
        <td mat-footer-cell *matFooterCellDef style="font-weight:bold">Total</td>
      </ng-container>
      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef>Total</th>
        <td mat-cell *matCellDef="let row">{{ row.amount + row.surcharge + row.interest | currency:'&#8369; ' }}</td>
        <td mat-footer-cell *matFooterCellDef style="font-weight:bold">{{ total() | currency:'&#8369; ' }}</td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let row">{{ row.amount | currency:'&#8369; ' }}</td>
        <td mat-footer-cell *matFooterCellDef style="font-weight:bold">{{ amount() | currency:'&#8369; ' }}</td>
      </ng-container>
      <ng-container matColumnDef="surcharge">
        <th mat-header-cell *matHeaderCellDef>Surcharge</th>
        <td mat-cell *matCellDef="let row">{{ row.surcharge | currency:'&#8369; ' }}</td>
        <td mat-footer-cell *matFooterCellDef style="font-weight:bold">{{ surcharge() | currency:'&#8369; ' }}</td>
      </ng-container>
      <ng-container matColumnDef="interest">
        <th mat-header-cell *matHeaderCellDef>Interest</th>
        <td mat-cell *matCellDef="let row">{{ row.interest | currency:'&#8369; ' }}</td>
        <td mat-footer-cell *matFooterCellDef style="font-weight:bold">{{ interest() | currency:'&#8369; ' }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
    </table>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button class="mr-3" [mat-dialog-close]>Close</button>
</div>
