import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRouterModule } from './app-router.module';

import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { NavMenuChildComponent } from './nav-menu/nav-menu-child.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfilePasswordComponent } from './profile/profile-password.component';
import { HomeBusinessComponent } from './home/home-business.component';

import { ErrorInterceptorService } from 'src/app/_interceptors/error-interceptor.service';
import { JwtInterceptorService } from 'src/app/_interceptors/jwt-interceptor.service';
import { HomeService } from 'src/app/_services/home.service';
import { PaymentService } from './_services/businesspermit.service';
import { SignalRService } from './_services/signal-r.service';

import { LoginRegistrationComponent } from './login/login-registration.component';
import { LoginForgotPasswordComponent } from './login/login-forgot-password.component';
import { LoginResetPasswordComponent } from './login/login-reset-password.component';
import { OnlyNumber } from './directives/digitonly.directive';
import { BlockCopyPasteDirective } from './directives/blockcopypastecut.directive';
import { HomeBusinessAddComponent } from './home/home-business-add.component';
import { MaterialModule } from './imports/material.module';
import { HomeTransactionViewComponent } from './home/home-transaction-view.component';
import { LoginRegistrationMobilenumberComponent } from './login/login-registration-mobilenumber.component';
import { ConfirmationComponent } from './shared/confirmation.component';
import { RegisterAddLineofBusinessComponent } from './onlineapplication/business/register/register-add-lineofbusiness.component';
import { RenewAddLineofBusinessComponent } from './onlineapplication/business/renew/renew-add-lineofbusiness.component';
import { RegisterAddVehicleComponent } from './onlineapplication/business/register/register-add-vehicle.component';
import { RenewAddVehicleComponent } from './onlineapplication/business/renew/renew-add-vehicle.component';
import { PromptMessageComponent } from './shared/prompt-message.component';
import { ViewImageComponent } from './shared/view-image.component';
import { PromptGuideComponent } from './shared/prompt-guide.component';
import { ChecklistRequirementsComponent } from './shared/checklist-requirements.component';
import { RegisterPersonComponent } from './onlineapplication/business/register/register-person.component';
import { RegisterCompanyComponent } from './onlineapplication/business/register/register-company.component';
import { RenewPersonComponent } from './onlineapplication/business/renew/renew-person.component';
import { RenewCompanyComponent } from './onlineapplication/business/renew/renew-company.component';
import { ApplicationTrackerComponent } from './onlineapplication/business/applications/application-tracker.component';
import { QuickAccessComponent } from './shared/quick-access.component';
import { DataPrivacyComponent } from './shared/data-privacy.component';
import { DownloadableFormsComponent } from './shared/downloadable-forms.component';
import { HomeBusinessBilldetailsComponent } from './home/home-business-billdetails.component';
import { HomePaymentSummaryComponent } from './home/home-payment-summary.component';
import { HomePaymentCancelledComponent } from './home/home-payment-cancelled.component';
import { HomePaymentErrorComponent } from './home/home-payment-error.component';
import { HelperService } from './_services/helper.service';
import { EServicesComponent } from './e-services/e-services.component';
import { TermsAndConditionsComponent } from './shared/terms-and-conditions.component';
import { EServicesBottomSheetComponent } from './e-services/e-services-bottomsheet.component';
import { TextMaskModule } from 'angular2-text-mask';
import { LoginRegistrationConfirmComponent } from './login/login-registration-confirm.component';
import { LoginForgotMobileNumberComponent } from './login/login-forgot-mobilenumber.component';
import { LoginResetMobileNumberComponent } from './login/login-reset-mobilenumber.component';
import { PromptRemarksModule } from './shared/prompt-remarks/prompt-remarks.module';
import { ApplicationListService } from './_services/business/applicationlist';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    NavMenuComponent,
    NavMenuChildComponent,
    LoginComponent,
    LoginRegistrationComponent,
    LoginRegistrationMobilenumberComponent,
    LoginRegistrationConfirmComponent,
    LoginForgotPasswordComponent,
    LoginResetPasswordComponent,
    LoginForgotMobileNumberComponent,
    LoginResetMobileNumberComponent,
    HomeComponent,
    HomeBusinessComponent,
    HomeBusinessAddComponent,
    HomeTransactionViewComponent,
    HomeBusinessBilldetailsComponent,
    ProfileComponent,
    ProfilePasswordComponent,
    OnlyNumber,
    BlockCopyPasteDirective,
    ConfirmationComponent,
    PromptMessageComponent,
    PromptGuideComponent,
    ViewImageComponent,
    ChecklistRequirementsComponent,
    RegisterPersonComponent,
    RegisterCompanyComponent,
    RegisterAddLineofBusinessComponent,
    RegisterAddVehicleComponent,
    RenewAddLineofBusinessComponent,
    RenewAddVehicleComponent,
    RenewPersonComponent,
    RenewCompanyComponent,
    ApplicationTrackerComponent,
    QuickAccessComponent,
    DataPrivacyComponent,
    DownloadableFormsComponent,
    HomePaymentSummaryComponent,
    HomePaymentCancelledComponent,
    HomePaymentErrorComponent,
    EServicesComponent,
    TermsAndConditionsComponent,
    EServicesBottomSheetComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule, FormsModule, ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRouterModule, MaterialModule, TextMaskModule, PromptRemarksModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    HomeService,
    PaymentService,
    SignalRService,
    HelperService,
    ApplicationListService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    LoginRegistrationMobilenumberComponent, ProfilePasswordComponent, HomeBusinessComponent, HomeBusinessAddComponent, HomeTransactionViewComponent, HomeBusinessBilldetailsComponent, ConfirmationComponent, PromptMessageComponent, PromptGuideComponent, ViewImageComponent, ChecklistRequirementsComponent,
    RegisterPersonComponent, RegisterCompanyComponent, RegisterAddLineofBusinessComponent, RegisterAddVehicleComponent, RenewAddLineofBusinessComponent, RenewAddVehicleComponent, RenewPersonComponent, RenewCompanyComponent,
    ApplicationTrackerComponent, QuickAccessComponent, DataPrivacyComponent, DownloadableFormsComponent, TermsAndConditionsComponent
  ]
})
export class AppModule { }
