<div class="login login-register mb-4">
  <div class="container">
    <div class="login-form mat-elevation-z2">
      <div class="row">
        <!--Navotas Logo and Title-->
        <div class="login-column-header col-md-12">
          <div class="row">
            <div class="col-4">
              <div class="login-logo">
                <img src="/images/navotenoaako_logo.png" class="img-fluid" />
              </div>
            </div>
            <div class="col-8">
              <div class="login-logo-description">
                <img src="/images/online_services_and_description.png" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div class="login-column-content col-md-12">
          <h2 class="mat-headline mt-3 mb-4 font-weight-bold">Create Account</h2>
          <form [formGroup]="formgroup_register">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="note">
                      <span class="font-weight-bold">Note:</span><br />
                      Please fill-out all the &nbsp;<span class="text-danger font-weight-bold">required (*)</span>&nbsp; fields.
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12 mb-3">
                    <mat-form-field appearance="fill">
                      <mat-label>First Name</mat-label>
                      <input matInput autocomplete="off" class="text-uppercase" formControlName="firstName" required>
                      <mat-error *ngIf="formgroup_register.get('firstName').hasError('required')">
                        <b>First Name is required.</b>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mb-3">
                    <mat-form-field appearance="fill">
                      <mat-label>Middle Name</mat-label>
                      <input matInput autocomplete="off" class="text-uppercase" formControlName="middleName">
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mb-3">
                    <mat-form-field appearance="fill">
                      <mat-label>Last Name</mat-label>
                      <input matInput autocomplete="off" class="text-uppercase" formControlName="lastName" required>
                      <mat-error *ngIf="formgroup_register.get('lastName').hasError('required')">
                        <b>Last Name is required.</b>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mb-3">
                    <mat-form-field appearance="fill">
                      <mat-label>Mobile Number</mat-label>
                      <input matInput onlyNumber preventCopyPaste autocomplete="off" maxlength="11" formControlName="mobileNumber" pattern="[0-9]*" max="11" required>
                      <button type="button" *ngIf="formgroup_register.controls['mobileNumber'].value && formgroup_register.controls['mobileNumber'].value != verifiedMobileNumber && isOTPVerificationEnabled" mat-icon-button matSuffix (click)="verifyMobile()" [attr.aria-label]="'Verify Mobile'" tabindex="-1">
                        <mat-icon>send</mat-icon>
                      </button>
                      <mat-error *ngIf="formgroup_register.get('mobileNumber').hasError('required')">
                        <b>Mobile Number is required.</b>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="note">
                      <span class="font-weight-bold">Note:</span><br />
                      <b>Email address & Password</b> will be used for <span class="text-danger font-weight-bold">login</span>.
                    </div>
                  </div>
                </div>
                <div formGroupName="emailAddressFormGroup">
                  <div class="row mt-2">
                    <div class="col-md-12 mb-3">
                      <mat-form-field appearance="fill">
                        <mat-label>Email address</mat-label>
                        <input #emailAddressInput type="email" matInput autocomplete="off" formControlName="emailAddress" required>
                        <mat-error *ngIf="formgroup_register.get('emailAddressFormGroup.emailAddress').hasError('required')">
                          <b>Email Address is required.</b>
                        </mat-error>
                        <mat-error *ngIf="formgroup_register.get('emailAddressFormGroup.emailAddress').hasError('email')">
                          <b>Please enter a valid Email Address</b>
                        </mat-error>
                        <mat-error *ngIf="!formgroup_register.get('emailAddressFormGroup.emailAddress').hasError('required') && !formgroup_register.get('emailAddressFormGroup.emailAddress').hasError('email') && formgroup_register.get('emailAddressFormGroup.emailAddress')?.hasError('alreadyExist')">
                          <b>This Email Address has already exist</b>
                        </mat-error>
                        <mat-hint class="mat-hint-success" *ngIf="isEmailAddressCleared">
                          <b>This Email Address is okay to use</b>
                        </mat-hint>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 mb-3">
                      <mat-form-field appearance="fill">
                        <mat-label>Confirm email address</mat-label>
                        <input type="email" matInput preventCopyPaste autocomplete="off" formControlName="confirmEmailAddress" [errorStateMatcher]="errorMatcher" required>
                        <mat-error *ngIf="formgroup_register.get('emailAddressFormGroup.confirmEmailAddress').hasError('required')">
                          <b>Confirm Email Address is required.</b>
                        </mat-error>
                        <mat-error *ngIf="formgroup_register.get('emailAddressFormGroup.confirmEmailAddress').hasError('email')">
                          <b>Please enter a valid Email Address</b>
                        </mat-error>
                        <mat-error *ngIf="!formgroup_register.get('emailAddressFormGroup.confirmEmailAddress').hasError('required') && !formgroup_register.get('emailAddressFormGroup.confirmEmailAddress').hasError('email') && formgroup_register.get('emailAddressFormGroup').hasError('notSame')">
                          <b>Email Address do not match</b>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div formGroupName="passwordFormGroup">
                  <div class="row">
                    <div class="col-md-12 mb-3">
                      <mat-form-field appearance="fill">
                        <mat-label>Password</mat-label>
                        <input matInput autocomplete="off" [type]="hidepassword ? 'password' : 'text'" formControlName="password" required>
                        <button type="button" mat-icon-button matSuffix (click)="hidepassword = !hidepassword" [attr.aria-label]="'Hide password'" tabindex="-1">
                          <mat-icon>{{hidepassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error *ngIf="formgroup_register.get('passwordFormGroup.password').hasError('required')">
                          <b>Password is required.</b>
                        </mat-error>
                        <mat-error *ngIf="formgroup_register.get('passwordFormGroup.password').hasError('pattern')">
                          <b>Special Character is not allowed.</b>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 mb-3">
                      <mat-form-field appearance="fill">
                        <mat-label>Confirm password</mat-label>
                        <input matInput autocomplete="off"  [type]="hideconfirmpassword ? 'password' : 'text'" formControlName="confirmPassword" [errorStateMatcher]="errorMatcher" required>
                        <button type="button" mat-icon-button matSuffix (click)="hideconfirmpassword = !hideconfirmpassword" [attr.aria-label]="'Hide confirm password'" tabindex="-1">
                          <mat-icon>{{hideconfirmpassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error *ngIf="formgroup_register.get('passwordFormGroup.confirmPassword').hasError('required')">
                          <b>Confirm Password is required.</b>
                        </mat-error>
                        <mat-error *ngIf="!formgroup_register.get('passwordFormGroup.confirmPassword').hasError('required') && formgroup_register.get('passwordFormGroup').hasError('notSame')">
                          <b>Passwords do not match</b>
                        </mat-error>
                        <mat-error *ngIf="!(!formgroup_register.get('passwordFormGroup.confirmPassword').hasError('required') && formgroup_register.get('passwordFormGroup').hasError('notSame')) && formgroup_register.get('passwordFormGroup.password').hasError('pattern')">
                          <b>Special Character is not allowed.</b>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3 mt-4">
              <div class="col-md-12">
                <label class="text-danger font-weight-bold">Terms & Agreement</label><br />
                <small>1. The information provided is certified as TRUE and CORRECT.</small><br />
                <small>2. The registrant should validate their account by clicking the CONFIRMATION LINK sent to the registered email address.</small><br />
                <small>3. The registrant should not create multiple FALSE ACCOUNTS.</small><br />
                <small>4. The registrant should keep their account confidential and will NOT SHARE to anyone.</small><br /><br />
                <label class="text-danger font-weight-bold">Data Privacy</label><br />
                <small style="display:block;text-align:justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; I hereby agree that all Personal Data (as defined under the Data Privacy Law of 2012 and its implementing rules and regulations), customer data and account or transaction information or records (collectively, the "information") which may be with City Government from time to time relating to us may be processed, profiled or shared to requesting parties or for the purpose of any court, legal process, examination, inquiry, audit or investigation of any Authority. The aforesaid terms shall apply notwithstanding any applicable non-disclosure agreement. We acknowledge that such information may be processed or profiled by or shared with jurisdictions which do not have strict data protection or data privacy laws.</small>
                <div class="mt-4 text-right mr-3">
                  <mat-checkbox color="primary" [(ngModel)]="agreed" [ngModelOptions]="{standalone: true}" tabindex="-1">I agree</mat-checkbox>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <a mat-button class="login-btn-back" href="javascript:void(0)" (click)="backToSignIn()">Back to Log In</a>
              </div>
              <div class="col-4">
                <div class="text-right">
                  <div matTooltip="Please fill-out all the required fields and agree the Terms & Agreement and Data Privacy."
                       [matTooltipDisabled]="!(formgroup_register.invalid || !agreed)"
                       matTooltipPosition="above">
                    <button id="btn-create"
                            mat-flat-button color="primary"
                            (click)="create()"
                            [disabled]="formgroup_register.invalid || !agreed">
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="loading-screen" *ngIf="isLoading">
            <div class="div-center">
              <mat-spinner></mat-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
