import { Component, Input, ViewChild, OnInit, ElementRef, Output, EventEmitter } from '@angular/core';
import { Navigation, Page } from 'src/app/_models/system';

@Component({
    selector: 'app-nav-menu-child',
    templateUrl: './nav-menu-child.component.html',
    styleUrls: ['./nav-menu-child.component.css']
})

export class NavMenuChildComponent implements OnInit {
  @Input() nav: Navigation[];
  @Input() pages: Page[];
  @ViewChild('navRef', { static: true }) childNavRef;
  @Output() public notifyParentEvent = new EventEmitter();

  notifyParent(event) {
    this.notifyParentEvent.emit(event);
  }

  constructor() {
  }

  ngOnInit() {
  }
}
