<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 mobile-column">
      <div class="payment">
        <div class="sidemenu-parent">
          <!--Left Sidemenu-->
          <div class="sidemenu-left">
            <div class="sidemenu sidemenu-list-parent business-list" is-middle-selected="false" is-first-selected="true">
              <div class="sidemenu-list">
                <div class="row mb-1">
                  <div class="col-md-12" style="position:relative;">  
                    <h2 class="mat-title sidemenu-title" [matBadgeHidden]="busIDs != undefined && busIDs.length > 0 ? false : true" [matBadge]="busIDs?.length" matBadgeOverlap="false">Your Business</h2>
                    <button *ngIf="!isLoadingBusinessList && !(busIDs == undefined || busIDs.length < 1) && busIDs.length > 1" mat-mini-fab color="primary" type="button" class="btn-select-business btn-previous" aria-label="Prev" (click)="prevSelectBusiness()">
                      <mat-icon>chevron_left</mat-icon>
                    </button>
                    <button *ngIf="!isLoadingBusinessList && !(busIDs == undefined || busIDs.length < 1) && busIDs.length > 1" mat-mini-fab color="primary" type="button" class="btn-select-business btn-next" aria-label="Next" (click)="nextSelectBusiness()">
                      <mat-icon>chevron_right</mat-icon>
                    </button>
                  </div>
                </div>
                <!--Add your business-->
                <button type="button" mat-stroked-button class="btn-add-business btn-size-small btn-float-mobile" [disabled]="isLoadingBusinessList" [matMenuTriggerFor]="menuAddBusiness">Add Business</button>
                <mat-menu #menuAddBusiness="matMenu">
                  <button mat-menu-item (click)="addBusiness()" class="font-weight-bold"><mat-icon>note_add</mat-icon> Add Existing Business</button>
                  <button mat-menu-item [routerLink]="'/' + 'OnlineApplication/Business/Register'" class="font-weight-bold"><mat-icon>add_business</mat-icon> Register New Business</button>
                </mat-menu>
                <!--Track your business-->
                <button type="button" mat-stroked-button [disabled]="isLoadingBusinessList" [routerLink]="'/OnlineApplication/Business/Applications'" class="btn-track-business btn-size-small">Application History</button>
                <hr class="sidemenu-title-hr" />
                <div *ngIf="isLoadingBusinessList" class="sidemenu-height">
                  <div class="div-center">
                    <div class="text-center">
                      <mat-spinner></mat-spinner>
                    </div>
                  </div>
                </div>
                <!--Business List-->
                <ng-container *ngIf="!isLoadingBusinessList">
                  <div *ngIf="busIDs == undefined || busIDs.length < 1" class="sidemenu-height">
                    <div class="div-center">
                      <div class="text-center">
                        <h1 class="mb-0"><mat-icon class="no-item-icon">business</mat-icon></h1>
                        <label>No business has been added.</label>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!(busIDs == undefined || busIDs.length < 1)" class="item-list">
                      <mat-card *ngFor="let buslink of busIDs; index as i" [ngClass]="{'selected': buslink==selectedBusID}" (click)="buslink != selectedBusID && selectBusID(buslink)" class="business">
                        <mat-card-content [attr.index]="i" [attr.is-middle]="i != 0 && i != busIDs.length - 1 ? true : false">
                          <!--Business ID-->
                          <h2 class="business-id mat-body highlight-text red mr-2 mb-2">{{ (buslink.busID != undefined ? buslink.busID : 'New Business')  }}</h2>
                          <!--Status Type-->
                          <!--Show Status Type when application has existing value-->
                          <h2 *ngIf="buslink.applications?.length > 0"
                              class="business-status-type mat-body highlight-text"
                              [ngClass]="highlightStatus(buslink)">
                            {{ displayStatusDescription(buslink) }}
                          </h2>
                          <div class="row">
                            <div class="col-md-12 mb-3">
                              <!--Business Name-->
                              <h2 class="business-name mat-body truncate">{{ buslink.busName | uppercase }}</h2>
                              <!--Business Address-->
                              <small class="mat-hint truncate">{{ (buslink.busAddress != undefined ? buslink.busAddress : '[No Business Address]') | uppercase }}</small>
                            </div>
                          </div>
                          <!--Business Owner-->
                          <h2 class="business-owner mat-body truncate">Owner:&nbsp;&nbsp;<span class="owner">{{ (buslink.busOwner != undefined ? buslink.busOwner : '[No Owner]') | uppercase }}</span></h2>
                        </mat-card-content>
                        <mat-card-actions class="mb-0">
                          <div class="pl-4 pr-4"> 
                            <!--Remove Business button-->
                            <button *ngIf="buslink.orNumber != null" mat-stroked-button class="btn-size-small mb-0 mr-2" (click)="removeBusiness(buslink)">Remove</button>
                          </div>
                        </mat-card-actions>
                      </mat-card>
                  </div>
                </ng-container>
              </div>
              <button *ngIf="!isLoadingBusinessList" [matMenuTriggerFor]="menuAddBusiness" mat-fab color="primary" type="button" class="btn-float-desktop btn-add-business" title="Add Business"><mat-icon>add</mat-icon></button>
            </div>
          </div>
          <!--Right Sidemenu-->
          <div class="sidemenu-right">
            <div class="sidemenu">
              <div class="sidemenu-list">
                <div [hidden]="!selectedBusID" class="row">
                  <div class="column-business-tab col-md-12 text-center">
                    <mat-button-toggle-group aria-label="Select tab">
                      <mat-button-toggle id="btn-show-application" class="mat-body mb-0" (click)="showApplication(true)"><mat-icon inline="true">approval</mat-icon>&nbsp;&nbsp; Your Application</mat-button-toggle>
                      <mat-button-toggle id="btn-show-business-tax" class="mat-body mb-0" (click)="showApplication(false)"><mat-icon inline="true">dynamic_feed</mat-icon>&nbsp;&nbsp; Your Business Tax</mat-button-toggle>
                    </mat-button-toggle-group>
                  </div>
                  <!--Status-->
                  <div [hidden]="isShowApplication == false" class="column-business-status col-md-5 mb-4">
                    <mat-card *ngIf="selectedBusID?.applications?.length > 0" class="mat-card-business-status highlight-text" [ngClass]="highlightStatus(selectedBusID)">
                      <mat-card-header>
                        <!--Status Description-->
                        <mat-card-title class="font-weight-bold">{{ displayStatusDescription(selectedBusID) }}</mat-card-title>
                        <mat-card-subtitle>Status</mat-card-subtitle>
                      </mat-card-header>
                      <mat-card-content>
                        <!--Remarks-->
                        <h2 class="mat-body font-weight-bold text-uppercase">{{ getStatusTypeRemarks(selectedBusID) }}</h2>
                        <small class="mat-hint">Remarks</small>
                      </mat-card-content>
                      <mat-card-actions>
                        <button mat-flat-button color="primary" class="btn-size-small mt-2" (click)="viewBusinessApplication(selectedBusID)">{{ checkBusinessIfNeedToEdit(selectedBusID) ? 'Update' : 'View your application' }}</button>
                        <button *ngIf="checkBusinessIfNeedToEdit(selectedBusID)" mat-flat-button color="warn" class="btn-size-small mt-2 ml-2" (click)="cancelBusinessApplication(selectedBusID)">Cancel application</button>
                      </mat-card-actions>
                    </mat-card>
                    <mat-card *ngIf="!(selectedBusID?.applications?.length > 0)" class="mat-card-business-status highlight-text">
                      <mat-card-header>
                        <mat-card-title>Application</mat-card-title>
                      </mat-card-header>
                      <mat-card-content>
                        <div class="no-item-container">
                          <div class="div-center">
                            <div class="text-center">
                              <h1 class="mb-0"><mat-icon class="no-item-icon">approval</mat-icon></h1>
                              <label>No application available.</label>
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                      <mat-card-actions>
                        <!--Renew Business Button-->
                        <!--Hide Renew button when there's no UserBusiness ID-->
                        <button *ngIf="selectedBusID?.canRenew" mat-flat-button color="primary" class="btn-size-small mb-0" (click)="renewBusiness(selectedBusID)">Renew your business</button>
                      </mat-card-actions>
                    </mat-card>
                  </div>
                  <!--Business Tax-->
                  <div [hidden]="isShowApplication == true" class="column-business-tax col-md-5 mb-4">
                    <mat-card class="pb-1">
                      <mat-card-header style="position:relative;">
                        <mat-card-title>Business Tax</mat-card-title>
                        <!--Refresh button-->
                        <button mat-flat-button color="primary" class="btn-refresh btn-size-small" (click)="selectBusID(selectedBusID, true)">Refresh</button>
                      </mat-card-header>
                      <mat-card-content>
                        <div *ngIf="isLoadingResults" style="height:25vh;">
                          <div class="div-center">
                            <mat-spinner></mat-spinner>
                          </div>
                        </div>
                        <div [hidden]="isLoadingResults">
                          <div *ngIf="!(bills && bills.length > 0)" class="no-item-container">
                            <div class="div-center">
                              <div class="text-center">
                                <h1 class="mb-0"><mat-icon class="no-item-icon">dynamic_feed</mat-icon></h1>
                                <label>No bills available.</label>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="bills && bills.length > 0" class="card-list">
                            <ng-container *ngFor="let row of bills">
                              <!--Parent-->
                              <div *ngIf="row.parent == true" [id]="row.year" class="card-container">
                                <div class="row">
                                  <div class="col-10">
                                    <div class="row">
                                      <div class="col-2 card-container-checkbox-area">
                                        <mat-checkbox color="primary"
                                                      [(ngModel)]="row.selected"
                                                      [disabled]="row.readOnly"
                                                      (change)="checkBills(row)"
                                                      [indeterminate]="row.indeterminate"
                                                      [ngClass]="{'partial-bill': (row.readOnly == undefined || row.readOnly == false) && row.indeterminate}">
                                        </mat-checkbox>
                                      </div>
                                      <div class="col-10 pl-0">
                                        <h2 class="card-container-title mat-body">{{ toTitleCase(row.description) + ' ' + row.year}}</h2>
                                        <h2 class="mat-body mb-0">Total:&nbsp; <b>{{ computeTotalOfAllQuarters(row) | currency:'&#8369; ' }}</b></h2>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-2 card-container-total-area">
                                    <button mat-icon-button aria-label="Show Bills" class="btn-toggle-bills" (click)="toggleDisplayBills(row)">
                                      <mat-icon>{{ row.show ? 'expand_more' : 'chevron_left' }}</mat-icon>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <!--Bills-->
                              <div *ngIf="row.parent == undefined" [ngClass]="{'hide-bill': (row.single == undefined || row.single == false) && (row.show == undefined || row.show == false)}" class="card-container card-container-child ">
                                <div class="row">
                                  <div class="col-7">
                                    <div class="row" [ngStyle]="row.periodID > 0 && {'margin-left': '0.25rem'}">
                                      <div class="col-3 card-container-checkbox-area">
                                        <mat-checkbox color="primary" [(ngModel)]="row.selected" [disabled]="row.readOnly" (change)="checkBills(row)"></mat-checkbox>
                                      </div>
                                      <div class="col-9 pl-0">
                                        <h2 class="card-container-title mat-body">{{ getPaymentType(row.periodID) + (row.periodID == 0 ? ' ' + row.year : '') }}</h2>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-5 card-container-total-area">
                                    <h2 class="card-container-total mat-subheading-1">{{ row.amount + row.surcharge + row.interest | currency:'&#8369; ' }}</h2>
                                    <button mat-stroked-button (click)="viewBillDetails(row)" class="card-container-action-button">
                                      View Details
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                            <button mat-flat-button color="primary" class="btn-total-pay" style="height:50px;width:100%;" (click)="selectBills()">
                              Pay&nbsp; {{ billTotalAmount | currency:'&#8369; ' }}
                            </button>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                  <!--Recent Transactions-->
                  <div [hidden]="isShowApplication == true" class="column-business-recent-transactions col-md-7 mb-4">
                    <mat-card class="pb-1">
                      <mat-card-header>
                        <mat-card-title>Recent Transactions</mat-card-title>
                      </mat-card-header>
                      <mat-card-content>
                        <div *ngIf=" " style="height:25vh;">
                          <div class="div-center">
                            <mat-spinner></mat-spinner>
                          </div>
                        </div>
                        <div [hidden]="isLoadingResults">
                          <div *ngIf="!(transData && transData.length > 0)" class="no-item-container">
                            <div class="div-center">
                              <div class="text-center">
                                <h1 class="mb-0"><mat-icon class="no-item-icon">receipt</mat-icon></h1>
                                <label>No transactions available.</label>
                              </div>
                            </div>
                          </div>
                          <div [hidden]="!(transData && transData.length > 0)" style="padding:0 5px 5px 5px;">
                            <div [hidden]="isLoadingTable">
                              <div *ngFor="let row of transData" class="card-container">
                                <div class="row mb-1">
                                  <div class="col-7">
                                    <div class="row">
                                      <div class="col-3 card-container-image-area">
                                        <div class="card-container-image-icon">
                                          <img class="img-fluid" [src]="row.gateway?.src != null ? row.gateway.src : 'assets/gateways/default-gateway.png'" />
                                        </div>
                                      </div>
                                      <div class="col-9 card-container-title-area">
                                        <h2 class="card-container-title mat-small">Ref. No. <b>{{ row.refNumber }}</b></h2>
                                        <h2 class="badge mb-0" [ngClass]="
                                          row.transactionStatus.id == 1 ? 'badge-pending'
                                          : row.transactionStatus.id == 2 ? 'badge-processing'
                                            : row.transactionStatus.id == 3 ? 'badge-paid'
                                              : row.transactionStatus.id == 4 ? 'badge-cancelled'
                                                : row.transactionStatus.id == 5 ? 'badge-for-refund'
                                                  : row.transactionStatus.id == 6 ? 'badge-refunded'
                                                    : row.transactionStatus.id == 7 ? 'badge-verified'
                                                      : row.transactionStatus.id == 9 ? 'badge-checkout'
                                                        : 'badge-dark'">
                                          <small>{{ row.transactionStatus.description }}</small>
                                        </h2>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-5 card-container-total-area">
                                    <h2 class="card-container-total mat-subheading-1">{{ (row.totalAmount || row.amount) | currency:'&#8369; ' }}</h2>
                                    <button mat-stroked-button (click)="viewTransactionHistory(row)" class="card-container-action-button">
                                      View Details
                                    </button>
                                  </div>
                                </div>
                                <mat-divider></mat-divider>
                              </div>
                              <mat-paginator [length]="transResultsLength" [pageSize]="transItemsPerPage" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
                            </div>
                            <div *ngIf="isLoadingTable" style="height:25vh">
                              <div class="div-center">
                                <mat-spinner></mat-spinner>
                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
