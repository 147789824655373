import { Injectable, Injector } from '@angular/core';
import { Transaction, Gateway, UserBusiness, GatewayTransaction } from 'src/app/_models/system';
import { BaseClass } from '../base.class';
import { HttpClient } from '@angular/common/http';
import { Bill, PrintData } from '../_models/treasury';

@Injectable()
export class PaymentService extends BaseClass {
  constructor(_injector: Injector) {
    super(_injector);
  }

  getBills(busid: string) {
    return this.injector.get(HttpClient).get<{ bills: Bill[], error: string, canrenew: boolean }>(`BusinessPermit/Payment/GetBills?busid=${busid}`);
  }

  getGateways() {
    return this.injector.get(HttpClient).get<Gateway[]>(`BusinessPermit/Payment/GetGateways`);
  }

  getTransactionList(pageindex: number, itemsperpage: number, busid: string) {
    return this.injector.get(HttpClient).get<{ items: Transaction[], count: number, error: string }>(`BusinessPermit/Payment/GetTransactionList?pageindex=${pageindex}&itemsperpage=${itemsperpage}&busid=${busid}`);
  }

  payBills(BusID: string, Year: number, Period: number, GatewayID: number) {
    return this.injector.get(HttpClient).post<{ error: string, transaction: Transaction, redirecturl: string, posturl: string, postdata: any, newtab: boolean, payorname: string }>(`BusinessPermit/Payment/PayBills`, { BusID, Year, Period, GatewayID });
  }

  getBusinessList() {
    return this.injector.get(HttpClient).get<UserBusiness[]>(`BusinessPermit/Payment/GetBusinessList`);
  }

  addBusiness(data: UserBusiness) {
    return this.injector.get(HttpClient).post<{ error: string, model: UserBusiness }>(`BusinessPermit/Payment/AddBusiness`, data);
  }

  removeBusiness(data: UserBusiness) {
    return this.injector.get(HttpClient).post<{ error: string }>(`BusinessPermit/Payment/RemoveBusiness`, data);
  }

  sendEmail(refnumber: string) {
    return this.injector.get(HttpClient).get<{ error: string }>(`BusinessPermit/Payment/SendEmail?refnumber=${refnumber}`);
  }

  viewPayment(refnumber: string, busid: string) {
    return this.injector.get(HttpClient).get<{ printdata: PrintData, gatewaytransaction: GatewayTransaction }>(`BusinessPermit/Payment/ViewPayment?refnumber=${refnumber}&busid=${busid}`);
  }
}
