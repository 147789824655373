import { AfterViewInit, Component, Inject, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from '../../base.class';
import { Transaction } from '../../_models/system';
import { HelperService } from '../../_services/helper.service';

declare var require: any
const FileSaver = require('file-saver');

@Component({
    selector: 'app-barcode',
    templateUrl: './barcode.component.html',
    styleUrls: ['./barcode.component.scss']
})
/** barcode component*/
export class BarcodeComponent extends BaseClass implements AfterViewInit {
  barcodewidth: number = 1.7;
  barcodeheight: number = 90;

  /** barcode ctor */
  constructor(_inject: Injector, private dialogRef: MatDialogRef<BarcodeComponent>, @Inject(MAT_DIALOG_DATA) public data: { transaction: Transaction }) {
    super(_inject)
  }

  ngAfterViewInit() {
    var canvas = <HTMLCanvasElement>document.getElementById("canvas-barcode"),
        ctx = canvas.getContext("2d")

    var amount = <HTMLElement>document.getElementById("label-transaction-amount"),
        validitydate = <HTMLElement>document.getElementById("label-transaction-validity-date");

    canvas.width = 275;
    canvas.height = 400;

    ctx.textAlign = "start";
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "red";
    ctx.font = 'bold 16px san-serif';
    ctx.fillText("Online Services", 80, 55);
    ctx.font = '14px san-serif';
    ctx.fillText("Valid Until", 170, 120);
    ctx.font = 'bold 16px san-serif';
    ctx.fillText(validitydate.innerText, 170, 145);

    ctx.fillStyle = "#212121";

    ctx.font = 'bold 13px san-serif';
    ctx.fillText("City Government of NAVOTAS", 80, 35);

    ctx.font = '14px san-serif';
    ctx.fillText("Amount", 20, 120);

    ctx.font = 'bold 18px san-serif';
    ctx.fillText(amount.innerText, 20, 145);
    ctx.font = '11px san-serif';
    ctx.fillText("This Reference No. can be use in our ff. partners:", 20, 320);
    
    type SvgInHtml = HTMLElement & SVGElement;
    var svg = document.querySelector('#pre-barcode svg') as SvgInHtml;
    var img = <HTMLImageElement>document.querySelector('#temp-barcode-image');

    var xml = new XMLSerializer().serializeToString(svg);
    var svg64 = btoa(xml);
    var b64Start = 'data:image/svg+xml;base64,';
    var image64 = b64Start + svg64;

    img.onload = function () {
      canvas.getContext('2d').drawImage(img, 15, 160);
    }
    img.src = image64;

    var image_navotas = <HTMLImageElement>document.querySelector('#temp-navotas-image'),
        image_unionbank = <HTMLImageElement>document.querySelector('#temp-unionbank-image');

    image_navotas.onload = function () {
      canvas.getContext('2d').drawImage(image_navotas, 15, 10, 60, 60);
    }
    image_unionbank.onload = function () {
      canvas.getContext('2d').drawImage(image_unionbank, 15, 340, 65, 40);
    }
  }

  download(filename) {
    var canvas = <HTMLCanvasElement>document.getElementById("canvas-barcode");
    var date = new Date();
    var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0];
    canvas.toBlob(function (blob) { FileSaver.saveAs(blob, (filename + "_" + dateString + ".jpeg")); });
  }
}
