import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-prompt-message',
    templateUrl: './prompt-message.component.html',
    styleUrls: ['./prompt-message.component.scss']
})
/** prompt.message component*/
export class PromptMessageComponent {
  error_keys: string[] = [];
  errors: string[] = [];
  /** prompt.message ctor */
  constructor(private dialogRef: MatDialogRef<PromptMessageComponent>, @Inject(MAT_DIALOG_DATA) public data: { type: string, icon: string, title: string, items: [] }) {
    if (this.data.type == 'checkfields') {
      if (typeof this.data.items == "object") {
        this.error_keys = Object.keys(this.data.items);

        for (var i = 0; i < this.error_keys.length; i++) {
          if (Array.isArray(this.data.items[this.error_keys[i]])) {
            for (var j = 0; j < this.data.items[this.error_keys[i]].length; j++) {
              this.errors.push(this.data.items[this.error_keys[i]][j])
            }
          }
          else {
            this.errors.push(this.data.items[this.error_keys[i]])
          }
        }
      }
      else if (typeof this.data.items == "string") {
        this.errors.push(this.data.items);
      }
    }
  }

  ok() {
    this.dialogRef.close(true)
  }
}
