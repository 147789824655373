<h1 mat-dialog-title>Add Existing Business</h1>
<form [formGroup]="formgroup_business_add">
  <div mat-dialog-content>
    <label class="mat-subheading-2 mb-1 font-weight-bold">Last Receipt Information</label><br />
    <p class="mat-body">Please indicate the details below of your business tax last receipt information.</p>
    <p class="mat-body">Click <a href="#" class="font-weight-bold" (click)="viewImage('assets/samples/last-receipt-information.jpg');$event.preventDefault()">here</a> to view the sample of last receipt.</p>
    <div class="row">
      <div class="col-md-12 mb-3 mt-2">
        <mat-form-field appearance="fill">
          <mat-label>Business ID</mat-label>
          <input matInput [textMask]="{mask: maskBusinessID}" [(ngModel)]="userBusiness.busID" type="text" class="txt-business-id" formControlName="validate_busid" required autocomplete="off">
          <mat-error><b>Business ID is required</b></mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-3">
        <mat-form-field appearance="fill">
          <mat-label>O.R. Number</mat-label>
          <input type="number" onKeyPress="if(this.value.length==8) return false;" matInput [(ngModel)]="userBusiness.orNumber" formControlName="validate_orno" required autocomplete="off">
          <mat-error><b>O.R Number is required</b></mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-3">
        <mat-form-field appearance="fill">
          <mat-label>Amount</mat-label>
          <input matInput currencyMask [options]="{ prefix: '₱ ', align: 'left', allowNegative: false }" [(ngModel)]="userBusiness.amount" type="text" formControlName="validate_amount" required autocomplete="off">
          <mat-error><b>Amount is required</b></mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]>Cancel</button>
    <button type="button" mat-flat-button color="primary" (click)="add()" [disabled]="formgroup_business_add.invalid">Add</button>
  </div>
</form>
<div class="loading-screen" *ngIf="isLoading">
  <div class="div-center">
    <mat-spinner></mat-spinner>
  </div>
</div>
