import { Component, Injector, Inject, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { BaseClass } from '../base.class';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Gateway, UserBusiness, computeMDR, setTransactionLocalStorage } from '../_models/system';
import { Bill } from '../_models/treasury';
import { PaymentService } from '../_services/businesspermit.service';
import { first } from 'rxjs/operators';
import { BarcodeComponent } from '../shared/barcode/barcode.component';
import { HelperService } from '../_services/helper.service';
import { PromptMessageComponent } from '../shared/prompt-message.component';

@Component({
  selector: 'app-home-business',
  templateUrl: './home-business.component.html',
  styleUrls: ['./home-business.component.scss']
})
export class HomeBusinessComponent extends BaseClass {
  computeMDR = computeMDR;
  gateways: Gateway[];
  selectedPaymentMode: number;

  isLoading: boolean = false;
  loadPaymentModes: boolean = false;

  target: string;
  postdata: string;
  posturl: string;
  @ViewChild('btnform', { static: false }) btnform: ElementRef<HTMLElement>;

  lookup_gateways = [];
  getImage = null;

  selectedGateway: Gateway;
  computedTotal: number = 0;

  constructor(_injector: Injector, private dialogRef: MatDialogRef<HomeBusinessComponent>, @Inject(MAT_DIALOG_DATA) public data: { selectedBusID: UserBusiness, selectedbills: Bill[], billTotalAmount: number }) {
    super(_injector);
    this.loadPaymentModes = true;

    this.computedTotal = data.billTotalAmount;

    // Get images of gateways
    this.lookup_gateways = this.injector.get(HelperService).getLookupGatewayImages();

    // Get gateways for Business
    this.injector.get(PaymentService).getGateways()
      .pipe(first())
      .subscribe(
        data => {
          this.gateways = data;
          this.loadPaymentModes = false;
        },
        error => {
          this.loadPaymentModes = false;
        }
      );
  }

  selectPaymentMode(id: number) {
    this.computedTotal = this.data.billTotalAmount;

    var paymentOptions = <HTMLCollection>document.getElementsByClassName("payment-method-option");
    this.selectedGateway = this.gateways.find(g => g.id == id);

    for (var i = 0; i < paymentOptions.length; i++) {
      if (paymentOptions[i].id === this.selectedGateway.id.toString()) {
        if (!paymentOptions[i].classList.contains("selected")) {
          this.selectedPaymentMode = id;

          for (var j = 0; j < paymentOptions.length; j++) {
            paymentOptions[j].classList.remove("selected");
          }

          this.computedTotal = this.data.billTotalAmount + computeMDR(this.selectedGateway, this.data.billTotalAmount);

          var selectedElement = (<HTMLElement>document.getElementById(this.selectedGateway.id.toString()));
          selectedElement.classList.add("selected");

          if (computeMDR(this.selectedGateway, this.data.billTotalAmount) > 0) {
            this.injector.get(MatDialog).open(PromptMessageComponent, {
              width: '250px',
              data: { type: "html", title: "Reminder", items: '<p class="mat-body mb-0">Please be informed that there will be additional &nbsp;<span class="text-danger font-weight-bold">' + selectedElement.getAttribute('data-mdr-amount') + '</span>&nbsp; as convenience fee for this transaction.<br/></p>'},
              disableClose: true,
              panelClass: ['mat-header', 'mat-header-danger']
            });
          }
        }
        else {
          this.computedTotal = this.data.billTotalAmount;
          
          this.selectedPaymentMode = undefined;
          this.selectedGateway = undefined;
          paymentOptions[i].classList.remove("selected");
        }
        
        break;
      }
    }
  }

  confirmPayment() {
    if (this.selectedPaymentMode == undefined) {
      this.injector.get(MatSnackBar).open('Please select a payment mode.', 'OK', {
        duration: 2000,
      });
      return false;
    }

    this.proceed()
  }

  proceed() {
    var lastbill: Bill;
    this.data.selectedbills.forEach(b => {
      lastbill = b;
    })

    this.isLoading = true;

    return this.injector.get(PaymentService).payBills(this.data.selectedBusID.busID, lastbill.year, lastbill.periodID, this.selectedPaymentMode == 5 ? null : this.selectedPaymentMode)
      .pipe(first())
      .subscribe(
        data => {
          if (data.error) {
            this.isLoading = false;
            this.injector.get(MatSnackBar).open(data.error, 'OK', {
              duration: 2000,
            });
          }
          else {
            if (data.transaction.gatewayID != null) {
              setTransactionLocalStorage(data.transaction, data.payorname);
              if (data.redirecturl) {
                if (data.newtab) {
                  window.open(data.redirecturl, '_blank');
                  this.isLoading = false;
                  this.dialogRef.close(true);
                }
                else {
                  window.location.href = data.redirecturl;
                }
              }
              else if (data.posturl) {
                this.posturl = data.posturl
                this.postdata = data.postdata;
                if (data.newtab) {
                  this.target = "_blank";
                  this.isLoading = false;
                  this.dialogRef.close(true);
                }
                else {
                  this.target = "_self";
                }
                this.injector.get(ChangeDetectorRef).detectChanges();
                this.btnform.nativeElement.click();
              }
            }
            else {
              this.isLoading = false;
              this.dialogRef.close(true);
              this.injector.get(MatDialog).open(BarcodeComponent, {
                disableClose: true,
                width: '320px',
                data: { transaction: data.transaction }
              });
            }
          }
        },
        error => {
          this.isLoading = false;
        });
  }

  getGatewayImage(id: number) {
    this.getImage = this.lookup_gateways.find(m => m.id === id)?.image;
    return this.getImage;
  }
}
