import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-prompt-remarks',
  templateUrl: './prompt-remarks.component.html',
  styleUrls: ['./prompt-remarks.component.scss']
})
/** promptremarks component*/
export class PromptRemarksComponent {
  remarks: string;

  /** promptremarks ctor */
  constructor(private dialogRef: MatDialogRef<PromptRemarksComponent>, @Inject(MAT_DIALOG_DATA) public data: { title: string, inputLabel: string }) { }

  proceed() {
    this.dialogRef.close({ confirm: true, remarks: this.remarks });
  }
}
