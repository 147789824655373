<ng-container *ngFor="let page of pages">
  <a mat-button [routerLink]="'/' + page.url.replace('Api/', '')" (click)="notifyLayout($event)">{{page.pageName}}</a>
</ng-container>
<ng-container *ngFor="let navigation of navigations">
  <ng-container *ngIf="(navigation.childNavigation && navigation.childNavigation.length > 0) || (navigation.pages && navigation.pages.length > 0)">
    <a mat-button [matMenuTriggerFor]="menu.childNavRef">{{navigation.name}}</a>
    <app-nav-menu-child #menu [nav]="navigation.childNavigation" [pages]="navigation.pages" (notifyParentEvent)="notifyLayout($event)"></app-nav-menu-child>
  </ng-container>
</ng-container>
<div style="margin-top:auto">
  <mat-slide-toggle color="primary" [(ngModel)]="isDarkMode" (click)="toggleTheme()" class="mb-3" style="font-size: 14px;font-weight: 500;">
    Dark mode
  </mat-slide-toggle>
  <a mat-button [routerLink]="['/']" (click)="notifyLayout($event);">E-Services</a>
  <a mat-button [routerLink]="'/profile'" (click)="notifyLayout($event)">Profile</a>
  <a mat-button (click)="logout()">Logout</a>
</div>
