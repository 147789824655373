import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { BaseClass } from '../base.class';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';

const SEPARATOR = ' > ';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css']
})

export class LayoutComponent extends BaseClass implements OnInit {
  routeTitle: string;
  @ViewChild('drawer') public drawer: MatSidenav;

  constructor(_injector: Injector) {
    super(_injector);
  }

  static ucFirst(text: string) {
    if (!text) { return text; }
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  ngOnInit() {
    this.injector.get(Router).events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        let route = this.injector.get(ActivatedRoute);
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      map((route) => route.snapshot),
      map((snapshot) => {
        if (snapshot.data.title) {
          if (snapshot.paramMap.get('id') !== null) {
            return snapshot.data.title + SEPARATOR + snapshot.paramMap.get('id');
          }
          return snapshot.data.title;
        } else {
          // If not, we do a little magic on the url to create an approximation
          return this.injector.get(Router).url.split('/').reduce((acc, frag) => {
            if (acc && frag) { acc += SEPARATOR; }
            return acc + LayoutComponent.ucFirst(frag);
          });
        }
      }))
      .subscribe((pathString) => this.routeTitle = `${pathString}`);
  }

  notify(event) {
    this.drawer.close();
  }
}
