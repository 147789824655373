import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HomeService } from 'src/app/_services/home.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PromptMessageComponent } from '../shared/prompt-message.component';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(
    private homeService: HomeService,
    private router: Router,
    private dialogRef: MatDialog,
    private matSnackBar: MatSnackBar
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        this.dialogRef.closeAll();
        this.homeService.logout();
        this.router.navigate(['/Login'], { queryParams: { returnUrl: this.router.url } });
        this.matSnackBar.open(`Couldn't verify that you're authorize to access that page. Please login and try again.`, 'OK', {
          duration: 3500,
        });
      }
      else if (err.status == 403) {
        this.matSnackBar.open('Access Denied', 'OK', {
          duration: 10000,
        });
      }
      else if (err.status == 404) {
        this.matSnackBar.open('Page not found', 'OK', {
          duration: 10000,
        });
      }
      else if (err.status == 429) {
        this.matSnackBar.open(err.error.message, 'OK', {
          duration: 10000,
        });
      }
      else if (err.status == 400 || err.status == 500) {
        if (err.error) {
          this.dialogRef.open(PromptMessageComponent, {
            width: "360px",
            data: { type: "checkfields", icon: "warning", title: "Failed", items: err.error },
            disableClose: true,
            panelClass: ['mat-header', 'mat-header-danger']
          });
        }
        else {
          this.dialogRef.open(PromptMessageComponent, {
            width: "360px",
            data: { type: "error", icon: "highlight_off", title: "Error", items: err.message },
            disableClose: true,
            panelClass: ['mat-header', 'mat-header-danger']
          });
        }
      }
      else if (err.status == 0) {
        this.matSnackBar.open("A connection error has occurred intermittently", 'OK', {
          duration: 10000,
        });
      }
      else {
        this.matSnackBar.open("Something went wrong", 'OK', {
          duration: 10000,
        });
      }

      return throwError(err);
    }))
  }
}
