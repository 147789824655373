<h1 mat-dialog-title>Generated Barcode</h1>
<mat-dialog-content>
  <div class="row">
    <div class="col-md-12">
      <canvas id="canvas-barcode">
      </canvas>
    </div>
  </div>
  <ngx-barcode [bc-value]="data.transaction.refNumber" [bc-width]="barcodewidth" [bc-height]="barcodeheight" [bc-display-value]="true" id="pre-barcode" style="display:none;"></ngx-barcode>
  <img id="temp-barcode-image" src="" style="display:none;" />
  <img id="temp-navotas-image" src="assets/icons/navotas.png" style="display:none;" />
  <img id="temp-unionbank-image" src="assets/gateways/unionbank.png" style="display:none;" />
  <label id="label-transaction-amount" style="display:none">{{ data.transaction.amount | currency:'&#8369; ' }}</label>
  <label id="label-transaction-validity-date" style="display:none">{{ data.transaction.validityDate | date: 'MMM dd, yyyy' }}</label>
</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]>Close</button>
  <button mat-flat-button color="primary" (click)="download(data.transaction.refNumber)">Download</button>
</div>
