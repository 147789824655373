import { ChangeDetectorRef, Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { BaseClass } from '../base.class';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from 'src/app/_services/home.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';
import { HelperService } from '../_services/helper.service';
import { MatDialog } from '@angular/material/dialog';
import { PromptMessageComponent } from '../shared/prompt-message.component';

@Component({
  selector: 'app-login-reset-mobilenumber',
  templateUrl: './login-reset-mobilenumber.component.html',
  styleUrls: ['./login-reset-mobilenumber.component.scss']
})
/** login.reset.mobilenumber component*/
export class LoginResetMobileNumberComponent extends BaseClass {
  public code: string;
  public emailAddress: string;
  public mobilenumber: string;
  public otpcode: string;
  public resendtimeoutseconds: number = 0;

  @ViewChild('txtOTPCode', { static: false }) txtOTPCode: ElementRef<HTMLElement>;

  isLoading: boolean = false;

  /** login.reset.mobilenumber ctor */
  constructor(_injector: Injector) {
    super(_injector)

    this.code = this.injector.get(ActivatedRoute).snapshot.queryParams['code'];
    this.emailAddress = this.injector.get(ActivatedRoute).snapshot.queryParams['email'];
    this.mobilenumber = this.injector.get(ActivatedRoute).snapshot.queryParams['mobilenumber'];
    var resendtimeout = Number(this.injector.get(ActivatedRoute).snapshot.queryParams['resendtimeout']);
    if (Number.isInteger(resendtimeout)) {
      this.setresendtimeout(resendtimeout);
    }
    var error = this.injector.get(ActivatedRoute).snapshot.queryParams['error'];
    if (error == 'true') {
      this.injector.get(MatSnackBar).open("Your mobile number reset request has been expired or has invalid token.", 'OK', {
        duration: 3000,
      });
      this.injector.get(Router).navigate(['/Login']);
    }
  }


  txtOTPCodeEvent(event: KeyboardEvent) {
    if (event.key == "Enter") {
      this.confirm();
    }
  }

  confirm() {
    if (this.otpcode == undefined || this.otpcode == '') {
      this.injector.get(MatSnackBar).open("Please enter the OTP", 'OK', {
        duration: 2000,
      });
      return false;
    }

    this.post()
  }

  changed() {
    this.injector.get(MatDialog).open(PromptMessageComponent, {
      data: { type: "html", icon: "check_circle", title: "Mobile Number has been changed successfully", items: '<p class="mat-subheading-2">You can now receive the OTP Code using your new mobile number.</p>' },
      disableClose: true,
      width: '450px',
      panelClass: ['mat-header', 'mat-header-success']
    });
    this.injector.get(HomeService).logout();
    this.injector.get(Router).navigate(['/Login']);
  }

  post() {
    this.injector.get(HelperService).disableClickableHTMLElements(document, true);
    this.isLoading = true;

    setTimeout(() => {
      this.injector.get(HomeService).resetMobileNumber(this.code, this.otpcode)
        .pipe(first())
        .subscribe(
          data => {
            if (data.verified) {
              this.changed()
            }
            else if (data.otpcreated) {
              this.injector.get(HelperService).disableClickableHTMLElements(document, false);
              this.isLoading = false;

              this.injector.get(MatSnackBar).open(`A One-Time Password was sent to your mobile number.`, 'OK', {
                duration: 2500,
              });

              this.setresendtimeout(data.resendtimeoutseconds);

              this.txtOTPCode.nativeElement.focus();
            }
            else {
              this.injector.get(HelperService).disableClickableHTMLElements(document, false);
              this.isLoading = false;

              this.injector.get(MatSnackBar).open(data.validationmsg || 'Something went wrong.', 'OK', {
                duration: 2000,
              });
            }
          },
          error => {
            this.injector.get(HelperService).disableClickableHTMLElements(document, false);
            this.isLoading = false;
          }
        );
    }, 1000);
  }

  setresendtimeout(seconds: number) {
    this.resendtimeoutseconds = seconds;
    var _this = this;
    var timerid = window.setInterval(function () {
      _this.resendtimeoutseconds--;
      if (_this.resendtimeoutseconds == 0) {
        clearInterval(timerid)
      }
    }, 1000);
  }

  resend() {
    this.post()
  }

  backToSignIn() {
    this.injector.get(Router).navigate(['/Login']);
  }
}
