import { Component, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseClass } from '../base.class';

@Component({
    selector: 'app-home-payment-error',
    templateUrl: './home-payment-error.component.html',
    styleUrls: ['./home-payment-error.component.scss']
})
/** home-payment-error component*/
export class HomePaymentErrorComponent extends BaseClass {

  public msg: string;

  /** home-business-payment-error ctor */
  constructor(_injector: Injector) {
    super(_injector);
    this.msg = this.injector.get(ActivatedRoute).snapshot.queryParams['msg'];
    this.injector.get(Router).navigate([]);
  }
}
