import { Component, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BaseClass } from '../base.class';
import { HomeService } from 'src/app/_services/home.service';
import { first } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HelperService } from '../_services/helper.service';

@Component({
  selector: 'app-login-forgot-password',
  templateUrl: './login-forgot-password.component.html',
  styleUrls: ['./login-forgot-password.component.scss']
})
/** login.forgot.password component*/
export class LoginForgotPasswordComponent extends BaseClass {
  emailAddress: string;

  isLoading: boolean = false;

  /** login.forgot.password ctor */
  constructor(_injector: Injector) {
    super(_injector);
  }

  send() {
    if (this.emailAddress == undefined || this.emailAddress.trim().length === 0) {
      this.injector.get(MatSnackBar).open("Please enter a valid email address", 'OK', {
        duration: 2500,
      });
      return;
    }

    this.injector.get(HelperService).disableClickableHTMLElements(document, true);
    this.isLoading = true;

    setTimeout(() => {
      this.injector.get(HomeService).forgotPassword(this.emailAddress)
        .pipe(first())
        .subscribe(
          data => {
            if (data.verified) {
              this.injector.get(MatSnackBar).open("We've sent an email to " + this.emailAddress + " to reset your password.", 'OK', {
                duration: 0,
              });
              this.injector.get(Router).navigate(['/Login']);
            }
            else {
              this.injector.get(HelperService).disableClickableHTMLElements(document, false);
              this.isLoading = false;

              this.injector.get(MatSnackBar).open(data.validationmsg || 'Something went wrong.', 'OK', {
                duration: 2000,
              });
            }
          },
          error => {
            this.injector.get(HelperService).disableClickableHTMLElements(document, false);
            this.isLoading = false;
          }
        );
    }, 1000);
  }

  backToSignIn() {
    this.injector.get(Router).navigate(['/Login']);
  }
}
