<!--Login-->
<div class="login">
  <div class="container">
    <div class="login-form mat-elevation-z2">
      <div class="row">
        <!--Navotas Logo and Title-->
        <div class="login-column-header col-md-6">
          <div class="row">
            <div class="col-md-12 col-sm-4 col-xs-4 col-4">
              <div class="login-logo">
                <img src="/images/navotenoaako_logo.png" class="img-fluid" />
              </div>
            </div>
            <div class="col-md-12 col-sm-8 col-xs-8 col-8">
              <div class="login-logo-description">
                <img src="/images/online_services_and_description.png" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>

        <!--<a href="https://www.flaticon.com/free-icons/online-payment" title="online payment icons">Online payment icons created by smashingstocks - Flaticon</a>-->
        <!--Login Form-->
        <div class="login-column-content col-md-6">
          <h2 class="mat-headline mt-3 mb-4 font-weight-bold">{{ otpvalidation == true ? 'One-Time Password' : 'Log in' }}</h2>
          <!--OTP Validation-->
          <ng-container *ngIf="otpvalidation == true">
            <div class="mb-3">
              <small>We just sent an SMS with a One-Time Password (OTP) code to your mobile number <span class="font-weight-bold">{{ otpmobilenumber }}</span>.</small><br /><br />
              <small class="font-weight-bold">Do you want to change your mobile number? <a href="javascript:void(0)" class="font-weight-bold" (click)="forgotMobileNumber()">Click here</a>.</small><br />
            </div>
            <div class="mb-2" [hidden]="resendtimeoutseconds == 0">
              <small>Please wait {{ resendtimeoutseconds }} seconds to resend the code.</small>
            </div>
            <div class="mb-2" [hidden]="resendtimeoutseconds != 0">
              <small>Didn't get the OTP? <a href="javascript:void(0)" (click)="resend()">Click here to resend</a>.</small>
            </div>
            <mat-form-field appearance="fill" class="mt-2 mb-4">
              <mat-label>Your OTP Code</mat-label>
              <input #txtOTPCode matInput [(ngModel)]="otpcode" autocomplete="off" (keypress)="txtOTPCodeEvent($event)" required>
              <mat-error><b>OTP Code is required</b></mat-error>
            </mat-form-field>
            <div class="row">
              <div class="col-8">
                <button mat-button class="login-btn-back" (click)="backtologin()">Back to Log In</button>
              </div>
              <div class="col-4">
                <div class="text-right">
                  <button #btnOTPVerify mat-flat-button color="primary" (click)="login()">Proceed</button>
                </div>
              </div>
            </div>
          </ng-container>
          <!--User login-->
          <ng-container *ngIf="otpvalidation == false">
            <!--Your Email Address-->
            <div class="row">
              <div class="col-md-12 mb-3">
                <mat-form-field appearance="fill">
                  <mat-label>Your Email Address</mat-label>
                  <input type="email" #txtUsername matInput [(ngModel)]="username" autocomplete="off" (keypress)="txtUsernameEvent($event)">
                  <mat-icon matPrefix>person</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <!--Your Password-->
            <div class="row">
              <div class="col-md-12 mb-4">
                <mat-form-field appearance="fill">
                  <mat-label>Your Password</mat-label>
                  <input #txtPassword matInput [(ngModel)]="password" [type]="hide ? 'password' : 'text'" autocomplete="off" (keypress)="txtPasswordEvent($event)">
                  <mat-icon matPrefix>lock</mat-icon>
                  <button *ngIf="password && password.trim().length > 0" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                  <mat-hint><a href="javascript:void(0)" class="login-btn-forgot-password" (click)="forgotPassword()">Forgot Password?</a></mat-hint>
                </mat-form-field>
              </div>
            </div>
            <div class="row mt-2 mb-4">
              <div class="col-md-12">
                <!--Log In button-->
                <button #btnLogin mat-flat-button color="primary" type="button" class="login-btn" (click)="login()">Log In</button>
                <!--Back to E-Services button-->
                <a mat-button [routerLink]="'/'" class="login-btn-other-option login-btn-back login-btn-back-eservices">Back to E-Services</a>
              </div>
            </div>
            <!--Create an Account button-->
            <div class="row">
              <div class="col-md-12 text-center">
                <button mat-stroked-button color="primary" type="button" class="login-btn-other-option login-btn-create-account" (click)="register()">Don't have an account? Register here</button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isLoading" class="loading-screen">
    <div class="div-center">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>


