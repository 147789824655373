import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { LoginComponent } from './login/login.component';

import { AuthGuardMainService } from './_guards/auth-guard-main.service';
import { AuthGuardLoginService } from './_guards/auth-guard-login.service';
import { LoginRegistrationComponent } from './login/login-registration.component';
import { LoginForgotPasswordComponent } from './login/login-forgot-password.component';
import { LoginResetPasswordComponent } from './login/login-reset-password.component';
import { HomePaymentSummaryComponent } from './home/home-payment-summary.component';
import { HomePaymentCancelledComponent } from './home/home-payment-cancelled.component';
import { HomePaymentErrorComponent } from './home/home-payment-error.component';
import { EServicesComponent } from './e-services/e-services.component';
import { AuthGuardUpdateClientVersion } from './_guards/auth-guard-update-client-version.service';
import { LoginRegistrationConfirmComponent } from './login/login-registration-confirm.component';
import { LoginForgotMobileNumberComponent } from './login/login-forgot-mobilenumber.component';
import { LoginResetMobileNumberComponent } from './login/login-reset-mobilenumber.component';

const routes: Routes = [
  { path: '', component: EServicesComponent, pathMatch: 'full', data: { title: 'E-Services' }, canActivate: [AuthGuardUpdateClientVersion] },
  {
    path: '', component: LayoutComponent, children: [
      { path: 'Home', component: HomeComponent, canActivate: [AuthGuardMainService, AuthGuardUpdateClientVersion], data: { title: 'Home' } },
      { path: 'profile', component: ProfileComponent, canActivate: [AuthGuardMainService, AuthGuardUpdateClientVersion], data: { title: 'Profile' } },
      { path: 'Maintenance', loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule) },
      { path: 'Transaction', loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionModule) },
      { path: 'Reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
      { path: 'OnlineApplication', loadChildren: () => import('./onlineapplication/onlineapplication.module').then(m => m.OnlineapplicationModule) },
      { path: 'RealProperty', loadChildren: () => import('./real-property-tax/real-property-tax.module').then(m => m.RealPropertyTaxModule) },
    ]
  },
  { path: 'Ovr', loadChildren: () => import('./businesstaxmisc/businesstaxmisc.module').then(m => m.BusinesstaxmiscModule) },
  { path: 'ovr', loadChildren: () => import('./businesstaxmisc/businesstaxmisc.module').then(m => m.BusinesstaxmiscModule) },
  { path: 'OVR', loadChildren: () => import('./businesstaxmisc/businesstaxmisc.module').then(m => m.BusinesstaxmiscModule) },
  { path: 'PaymentSummary', component: HomePaymentSummaryComponent, canActivate: [AuthGuardUpdateClientVersion] },
  { path: 'PaymentCancelled', component: HomePaymentCancelledComponent, canActivate: [AuthGuardUpdateClientVersion] },
  { path: 'PaymentError', component: HomePaymentErrorComponent, canActivate: [AuthGuardUpdateClientVersion] },
  { path: 'Login', component: LoginComponent, pathMatch: 'full', canActivate: [AuthGuardLoginService, AuthGuardUpdateClientVersion] },
  { path: 'Register', component: LoginRegistrationComponent, canActivate: [AuthGuardLoginService, AuthGuardUpdateClientVersion] },
  { path: 'ConfirmRegister', component: LoginRegistrationConfirmComponent, canActivate: [AuthGuardUpdateClientVersion] },
  { path: 'ForgotPassword', component: LoginForgotPasswordComponent, canActivate: [AuthGuardLoginService, AuthGuardUpdateClientVersion] },
  { path: 'ResetPassword', component: LoginResetPasswordComponent, canActivate: [AuthGuardUpdateClientVersion] },
  { path: 'ForgotMobileNumber', component: LoginForgotMobileNumberComponent, canActivate: [AuthGuardLoginService, AuthGuardUpdateClientVersion] },
  { path: 'ResetMobileNumber', component: LoginResetMobileNumberComponent, canActivate: [AuthGuardUpdateClientVersion] },
  { path: '**', redirectTo: '' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRouterModule {
}
